import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import axios from '../../axios/axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

const PageVisitsCard = () => {
    const [newUsers, setNewUsers] = useState([])

    const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate === today.getDate &&
          someDate.getMonth === today.getMonth &&
          someDate.getFullYear === today.getFullYear
      }

    useEffect(() => {
        
        axios.get('/users?isBlocked=0&isEntreprise=0')
         .then(res=>{
             
            let insc= res.data.filter((item)=>{
                isToday(new Date(item.emailVerifiedAt))
            });
            setNewUsers(insc);
            
         })
         .catch(err=>{
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
            })
         
    }, [])
    return (
        <Card>
            <CardHeader color="blue" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">Inscriptions du jours: {}</h2>
                    {/* <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                    >
                        See More
                    </Button> */}
                </div>
            </CardHeader>
            <CardBody>
               
                <div className="overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    ID
                                </th>
                                <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Email
                                </th>
                                <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Numéro de téléphone
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {newUsers.length>0 ? newUsers.map((user)=>{
                            <tr>
                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                    {user.id}
                                </th>
                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                    {user.email}
                                </td>
                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                    {user.phoneNumber}
                                </td>
                                
                            </tr>
                            }) : 
                            <tr>
                                 <td colSpan={3}>
                            <p className="text-gray-700 text-l">Aucune nouvelle inscription aujourd'hui.</p>
                            </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                 
            </CardBody>
        </Card>
    );
}
export default PageVisitsCard