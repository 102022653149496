import React, { useCallback, useState, useEffect} from 'react'
import { Button, Icon, Image, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from 'axios'
import axios1 from '../../axios/axios'
import Swal from 'sweetalert2'
import Select from 'react-select';
import { useDropzone } from 'react-dropzone'
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'
import withReactContent from 'sweetalert2-react-content'

const Pots = () => {
    const [showModal, setShowModal] = useState(false);
    const [showUPDATEModal, setShowUpdateModal] = useState(false);
    const [pot_id_update, setPotUpdate] = useState(null);
    const [size, setSize] = useState("")
    const [material, setMaterial] = useState("")
    const [contenance, setContenance] = useState(0)
    const [file, setFile] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [price, setPrice] = useState(0)
    const [pots, setPots] = useState([])
    //const [dessertId, setDessertId] = useState(null)
    //const [dessertOptions, setDessertOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const MySwal = withReactContent(Swal)
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFile(acceptedFiles[0]);
    }, [])
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file ? file.path : ""} - {file.size} bytes
        </li>
    ));

    useEffect(() => {
        axios.get('https://api.snack-time.fr/v1/pots')
         .then(res=>{
             setPots(res.data.data);
             setLoading(false)
             console.log(res.data);
         })
         .catch(err=>{
             console.log(err.response);
             setLoading(false)

             Swal.fire({
                 icon: 'error',
                 title: 'Oops...',
                 text: 'Une erreur est survenue !',
               })  
         })
         /* axios1.get('/desserts')
         .then(res=>{
             let t =[];
             var promises = res.data.map((item)=>{
                 t.push( {label:item.label,value:item.id})
             })
             Promise.all(promises)
            .then(function (results) {
                console.log(t);
             setDessertOptions(t);
            })
             
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
         }) */
     }, [])


    const COLUMNS = [
        {
            Header: 'Identifiant',
            accessor: 'id',
        },
        {
            Header: 'Image',
            accessor: 'cover',
            Cell: ({ row }) => (
                <div style={{display:"flex"}}>
                    {
                    row.original.cover.main ?
                    <div> 
                        <Image
                    style={{
                        height:'auto',
                        width:50,
                        
                    }}//http://127.0.0.1:8000
                    src={row.original.cover.main}
                    alt="pot snacktime"/>
                     <button className='text-green-400 hover:text-gray-300 mr-2 ml-10' onClick={() => {setPotUpdate(row.original.id);setShowImageModal(true);}}>
                    <Icon name="flip_camera_ios" size="2xl" />
                    </button>
                    </div>
                    : null }
                    
                </div>),
        },
        {
            Header: 'Taille',
            accessor: 'size'
        },
        {
            Header: 'Matière',
            accessor: 'material',
        },
        
        {
            Header: 'Contenance',
            accessor: 'contenance',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.contenance} ml</p>
                    
                </div>),
        },
        {
            Header: 'Prix',
            accessor: 'prix ',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.prix.toFixed(2)}€</p>
                    
                </div>),
        },
        
        /* {
            Header: 'Date Création',
            accessor: 'created_at',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.created_at).toLocaleDateString()}</p>
                    
                </div>),
        },
        {
            Header: 'Date Modification',
            accessor: 'updated_at',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.updated_at).toLocaleDateString()}</p>
                    
                </div>),
        }, */
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title='Modifier' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        setPotUpdate(row.original.id)
                        setSize(row.original.size)
                        setMaterial(row.original.material)
                        setContenance(row.original.contenance)
                        setPrice(row.original.prix)
                        setShowUpdateModal(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    <button title='Supprimer' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => openAlert(row.original.id)}
                    ><Icon name="delete" size="2xl" /></button>
                </div>),
        }
    ]


    //delete pot
    const openAlert = (delete_id) => {
    MySwal.fire({
        title: <p>Hello World</p>,
        
        didOpen: () => {
          // `MySwal` is a subclass of `Swal`
          //   with all the same instance & static methods
          MySwal.clickConfirm()
        }
      }).then(() => {
        return Swal.fire({
            title: 'Etes-vous sur de vouloir effacer ce pot?',
            text: "Cette action n'est pas reversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://api.snack-time.fr/v1/pots/${delete_id}`)
                .then(res=>{
                    setPots(pots.filter((item)=>item.id!==delete_id));
                    Swal.fire(
                        'Succès de suppression!',
                        'Votre occasion a été supprimé.',
                        'success'
                      )
                })
                .catch(err=>{
                    console.log(err.response);
       
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur est survenue !',
                      })  
                })
              
            }
          })
      })
      
  }
  //update pot
  const updatePot = () => {
    let  reqBody ={
            "size":size,
            "material":material,
            "contenance":contenance,
            "price":price
        }
    
    axios.put(`https://api.snack-time.fr/v1/pots/${pot_id_update}`,reqBody,{
        headers:{"content-type": "application/merge-patch+json"}
    })
         .then(res=>{
             setLoading(false)
             console.log(res.data);
             setSize("");
             setMaterial("");
             setContenance("");
             setPrice("");
             setShowUpdateModal(false);
             axios.get('https://api.snack-time.fr/v1/pots')
         .then(res=>{
             setPots(res.data.data);
             setLoading(false)
             console.log(res.data);
             Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title:'Succès de modification!',
                showConfirmButton: false,
                timer: 1500            
              })
         })
         .catch(err=>{
             console.log(err.response);
             setLoading(false)

             Swal.fire({
                 icon: 'error',
                 title: 'Oops...',
                 text: 'Une erreur est survenue !',
               })  
         })
            
         })
         .catch(err=>{
             console.log(err.response);
             setLoading(false)

             Swal.fire({
                 icon: 'error',
                 title: 'Oops...',
                 text: 'Une erreur est survenue !',
               })  
         })
  }

  //post pot
  const postPot = () =>{
    const formData = new FormData();
  
    // Update the formData object
    formData.append(
      "cover",
      file,
    );
    formData.append("size",size)
    formData.append("material",material)
    //formData.append("dessert_id",parseInt(dessertId.value))
    formData.append("contenance",parseInt(contenance))
    formData.append("price",parseFloat(price))

    axios.post("https://api.snack-time.fr/v1/pots/photo", formData,
    {headers:{"Content-Type": "multipart/form-data"}} )
    .then(res=>{
        setSize("");
        setMaterial("");
        //setDessertId(null);
        setFile(null);
        setContenance("");
        setPrice("");
      Swal.fire({
          toast:true,
          position: 'top-end',
          icon: 'success',
          title: 'Pot ajouté avec succès!',
          showConfirmButton: false,
          timer: 1500
        })
        axios.get('https://api.snack-time.fr/v1/pots')
        .then(res=>{
            setPots(res.data.data);
            setLoading(false)
            console.log(res.data);
        })
        .catch(err=>{
            console.log(err.response);
            setLoading(false)

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
        })
          setShowModal(false);

    })
    .catch(error=>{
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Une erreur d'ajout est survenue !",
        })  
    })
  }

  //pot img
  const updateImPot = () => {
    const formData = new FormData();
    formData.append(
      "cover",
      file,
    );
    axios.post(`https://api.snack-time.fr/v1/pots/photo/${pot_id_update}`, formData, 
    {headers:{"Content-Type": "multipart/form-data"}})
    
        .then(res=>{
            setShowImageModal(false);
            setFile(null);
            axios.get('https://api.snack-time.fr/v1/pots')
                .then(res=>{
                    setPots(res.data.data);
                })
                .catch(err=>{
                console.log(err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })  
                });
            Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Image modifiée avec succès!',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch(err=>{
            console.log('update pot im :::', err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Une erreur de modif de photo est survenue !",
              }) 
        })
    
  }
return (
        <>

            <div className="md:pl-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard onClick={setShowModal} COLUMNS={COLUMNS} DATA={pots}
                        description={"Cliquez sur le nom d'une colonne pour trier les données."} title={"Liste des pots"} {...loading} />
                    </div>
                </div>
            </div>

            <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
                    <ModalHeader toggler={() => setShowModal(false)}>
                        Ajouter un pot
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={size}
                                                onChange={(e)=>setSize(e.target.value)}
                                                placeholder="Taille"
                                            />
                                        {!size && <p className="text-snack-pink-original font-normal text-sm">Taille obligatoire</p>}

                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                
                                                outline={true}
                                                value={material}
                                                onChange={(e)=>setMaterial(e.target.value)}
                                                placeholder="Matière"
                                            />
                                        {!material && <p className="text-snack-pink-original font-normal text-sm">Matière obligatoire</p>}

                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={contenance}
                                                outline={true}
                                                onChange={(e)=>setContenance(e.target.value)}
                                                placeholder="Contenance"
                                            />
                                        {!contenance && <p className="text-snack-pink-original font-normal text-sm">Contenance obligatoire</p>}
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={price}
                                                outline={true}
                                                onChange={(e)=>setPrice(e.target.value)}
                                                placeholder="Prix"
                                            />
                                        {!price && <p className="text-snack-pink-original font-normal text-sm">Prix obligatoire</p>}
                                        </div>
                                        
                                        {/* <div className="mb-4">
                                        <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>
                                                        setDessertId(selectedOption)}
                                                    value={dessertId}
                                                    options={dessertOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Dessert"
                                                    isClearable={true} />
                                        {!dessertId && <p className="text-snack-pink-original font-normal text-sm">Dessert attribué obligatoire</p>}         
                                        </div> */}
                                        


                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Photo</label>
                                            <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700' : 'border-gray-300'}  border-dashed rounded-md`}>
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            {
                                                                isDragActive && files !== '' ?
                                                                    null :
                                                                    <span>Importer</span>
                                                            }

                                                            <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        {
                                                            isDragActive ?
                                                                <p className="pl-1">Drop the files here ...</p> :
                                                                <p className="pl-1">ou glissez un fichier ici</p>
                                                        }

                                                    </div>
                                                    {files !== '' ? <ul>{files}</ul> : <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>}


                                                </div>
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e) => setShowModal(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>
                       
                        {(size && material && contenance  && price) ? 
                        <Button
                            color="green"
                            onClick={postPot}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> 
                        : 
                        <Button
                            disabled
                            color="gray"
                            onClick={postPot}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> 
                        }
                    </ModalFooter>
                </Modal >


            <Modal size="lg" active={showUPDATEModal} toggler={() => setShowUpdateModal(false)}>
                <ModalHeader toggler={() => setShowUpdateModal(false)}>
                    Mettre à jour un pot
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                    <div className="mb-4">
                                        
                                        <Input
                                            value={size}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setSize(e.target.value);
                                            }}
                                            placeholder="Taille"
                                        />
                                    {!size && <p className="text-snack-pink-original font-normal text-sm">Taille obligatoire</p>}

                                    </div>
                                    <div className="mb-4">
                                        
                                        <Input
                                            value={material}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setMaterial(e.target.value);
                                            }}
                                            placeholder="Matière"
                                        />
                                    {!material && <p className="text-snack-pink-original font-normal text-sm">Matière du pot obligatoire</p>}

                                    </div>
                                    <div className="mb-4">
                                        
                                        <Input
                                            value={contenance}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setContenance(e.target.value);
                                            }}
                                            placeholder="Contenance"
                                        />
                                    {!contenance && <p className="text-snack-pink-original font-normal text-sm">Contenance du pot obligatoire</p>}

                                    </div>
                                    <div className="mb-4">
                                        
                                        <Input
                                            value={price}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setPrice(e.target.value);
                                            }}
                                            placeholder="Prix"
                                        />
                                    {!price && <p className="text-snack-pink-original font-normal text-sm">Prix du pot obligatoire</p>}

                                    </div>
                                   {/*  <div className="mb-4">
                                        <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>{
                                                        setDessertId(selectedOption)
                                                    }}
                                                    value={dessertId}
                                                    options={dessertOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Dessert"
                                                    isClearable={true} />
                                    {!dessertId && <p className="text-snack-pink-original font-normal text-sm">Type de dessert obligatoire</p>}

                                    </div> */}

                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowUpdateModal(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    {(size.length > 1 && material.length>1 && contenance.length!=0 && price !=0 ) ? 
                        <Button
                            color="green"
                            onClick={updatePot}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> :
                            <Button
                            color="gray"
                            disabled
                            onClick={updatePot}
                            ripple="light"
                        >
                            Enregistrer
                        </Button>
             
                    }
                </ModalFooter>
            </Modal >


            <Modal size="lg" active={showImageModal} toggler={() => setShowImageModal(false)}>
                    <ModalHeader toggler={() => setShowImageModal(false)}>
                        Modifier l'image
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                        
                                        
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Photo</label>
                                            <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700' : 'border-gray-300'}  border-dashed rounded-md`}>
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            {
                                                                isDragActive && files !== '' ?
                                                                    null :
                                                                    <span>Importer</span>
                                                            }

                                                            <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        {
                                                            isDragActive ?
                                                                <p className="pl-1">Drop the files here ...</p> :
                                                                <p className="pl-1">ou glissez un fichier ici</p>
                                                        }

                                                    </div>
                                                    {files !== '' ? <ul>{files}</ul> : <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>}


                                                </div>
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e)=>setShowImageModal(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>

                        { file ? <Button
                            
                            color=  "green"
                            onClick={updateImPot}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> : <Button
                            disabled
                            color=  "gray"
                            onClick={updateImPot}
                            ripple="light"
                        >
                            Enregistrer
                        </Button>}
                    </ModalFooter>
                </Modal >


           
        </>
      )
}
export default Pots;