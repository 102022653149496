import { createSlice } from '@reduxjs/toolkit'


export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    id : 0,
    email : "",
    roles : []
   },
  reducers: {
    loginUser(state, action) {
      state.id = action.payload.id;
      state.email = action.payload.username;
      state.roles = action.payload.roles;
    },
  },
})
export const { loginUser } = authSlice.actions
export const selectUserId = (state) => state.auth.id;
export const selectUserEmail = (state) => state.auth.email;

export default authSlice.reducer