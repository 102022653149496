import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Icon, Input, InputIcon, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import Select from 'react-select';
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'
import Swal from 'sweetalert2'
import Loader from 'react-loader-spinner';
import { useGlobalFilter, usePagination, useTable } from 'react-table/dist/react-table.development';

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
  
    const onChange = e => {
      setValue(e.target.value)
    }
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value)
      console.log("changed finished");
    }
  
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    return <input value={value.toFixed(2)} onChange={onChange} onBlur={onBlur} />
  }
  
  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell,
  }
  
  // Be sure to pass our updateMyData and the skipPageReset option
  function Table({ columns, data, updateMyData, skipPageReset }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // use the skipPageReset option to disable page resetting temporarily
        //autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
      },
      usePagination
    )
  
    // Render the UI for your table
    return (
      <>
        <table {...getTableProps()} className="items-center   w-full bg-transparent border-collapse">
          <thead >
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="px-2 text-gray-700 font-sofiapro align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()} className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                        <div className="flex-1 flex justify-between sm:hidden">
                            <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Précédent
                            </a>
                            <a
                                href="#"
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Suivant
                            </a>
                        </div>
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Montrant de <span className="font-medium">
                                        {(pageSize * (pageIndex + 1)) - pageSize + 1}</span> jusqu'à <span className="font-medium">{pageSize * (pageIndex + 1)}</span> de{' '}
                                    <span className="font-medium">{pageOptions.length}</span> resultats
                                </p>
                            </div>

                            <div>
                                {pageCount > 5 ?
                                    <nav className="relative z-0 inline-flex rounded-md shadow-sm " aria-label="Pagination">
                                        <button
                                            onClick={() => previousPage()}
                                            disabled={!canPreviousPage}
                                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Previous</span>
                                            <Icon name="navigate_before" size="2xl" />
                                        </button>

                                        <button
                                            onClick={() => gotoPage(0)}
                                            d
                                            className={`z-10 ${pageIndex === 0 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                        >
                                            1
                                        </button>
                                        {
                                            pageIndex <= 2 ?
                                                <div className={"inline-flex"}>
                                                    <button
                                                        onClick={() => gotoPage(1)}

                                                        className={`${pageIndex === 1 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                        2
                                                    </button>
                                                    <button
                                                        onClick={() => gotoPage(2)}
                                                        className={`${pageIndex === 2 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                        3
                                                    </button>
                                                    <button
                                                        onClick={() => nextPage()}
                                                        disabled={!canNextPage}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                        ...
                                                    </button></div>
                                                :
                                                pageIndex >= pageCount - 3 ?
                                                    <div className={"inline-flex"}><button
                                                        onClick={() => previousPage()}
                                                        disabled={!canPreviousPage}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                        ...
                                                    </button><button
                                                        onClick={() => gotoPage(pageCount - 3)}
                                                        className={`${pageIndex === pageCount - 3 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                            {pageCount - 2}
                                                        </button><button
                                                            onClick={() => gotoPage(pageCount - 2)}
                                                            className={`${pageIndex === pageCount - 2 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                        >
                                                            {pageCount - 1}
                                                        </button></div>
                                                    :
                                                    <div className={"inline-flex"}><button
                                                        onClick={() => previousPage()}
                                                        disabled={!canPreviousPage}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                        ...
                                                    </button><button
                                                        onClick={() => gotoPage(pageIndex - 1)}
                                                        className={`bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                            {pageIndex}
                                                        </button><button

                                                            className={`bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                        >
                                                            {pageIndex + 1}
                                                        </button><button
                                                            onClick={() => gotoPage(pageIndex + 1)}
                                                            className={`bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                        >
                                                            {pageIndex + 2}
                                                        </button><button
                                                            onClick={() => nextPage()}
                                                            disabled={!canNextPage}
                                                            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                            ...
                                                        </button></div>
                                        }

                                        <button
                                            onClick={() => gotoPage(pageCount - 1)}
                                            className={`${pageIndex === pageCount - 1 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                        >
                                            {pageCount}
                                        </button>
                                        <button
                                            onClick={() => nextPage()}
                                            disabled={!canNextPage}
                                            className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Suivant</span>
                                            <Icon name="navigate_next" size="2xl" />
                                        </button>
                                        <select
                                            value={pageSize}
                                            onChange={e => setPageSize(Number(e.target.value))}>
                                            {[10, 25, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Lister {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </nav>
                                    : <nav className="relative z-0 inline-flex rounded-md shadow-sm " aria-label="Pagination">
                                        <button

                                            onClick={() => previousPage()}
                                            disabled={!canPreviousPage}
                                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Précédent</span>
                                            <Icon name="navigate_before" size="2xl" />
                                        </button>
                                        <div className={"inline-flex"}>
                                            {pageOptions.map((row, index) => (
                                                <button key={index}
                                                    onClick={() => gotoPage(index)}
                                                    className={`${pageIndex === index ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                >
                                                    {index + 1}
                                                </button>
                                            ))}
                                        </div>


                                        <button

                                            onClick={() => nextPage()}
                                            disabled={!canNextPage}
                                            className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Suivant</span>
                                            <Icon name="navigate_next" size="2xl" />
                                        </button>
                                        <select
                                            value={pageSize}
                                            onChange={e => setPageSize(Number(e.target.value))}>
                                            {[10, 25, 50].map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    Lister {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </nav>
                                }
                            </div>
                        </div>
                    </div>
      </>
    );
  }

  
 const PrixRestaurants = () => {
    const [showADDModal, setShowADDModal] = useState(false);
    const [priceChanged, setPriceChanged] = useState("")
    const [loading, setLoading] = useState(false)
    const [entreprises, setEntreprises] = useState([]);
    const [variants, setVariants] = useState(null);
    const [selectedEntreprise, setSelectedEntreprise] = useState(null);
    
    useEffect(() => {
        
        axios.get('/users?isEntreprise=1&isBlocked=0')
         .then(res=>{
            setEntreprises(res.data);
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
            })
         
    }, [])
const columns = React.useMemo(
      () => [
        {
            Header: 'ID',
            accessor: 'id',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.id}</p>
                </div>),
        },
        {
            Header: 'Nom dessert',
            accessor: 'variant',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.variant.title}</p>
                </div>),
        },
        {
            Header: 'Prix ',
            accessor: 'price'
        },
        
      ],
      []
    )
  
    //const [skipPageReset, setSkipPageReset] = React.useState(false)
  
    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.
  
    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
      // We also turn on the flag to not reset the page
      //setSkipPageReset(true)
      setVariants(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            axios.patch(`prix_entreprises/${row.id}`, {"price":parseFloat(value)}, 
            {headers:{"content-type": "application/merge-patch+json"}})
            .then(res=>{
                Swal.fire({
                    toast:true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Prix modifié avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                })
                //setShowUpdateModal(false)
        
                
            })
            .catch(err=>{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur de mise à jours est survenue !',
                })  
            }) 
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
    }
    
    const getVariants =(id) => {
        setSelectedEntreprise(id)
        setLoading(true)
        axios.post('/prix/entreprise',{
            "entreprise_id": id
          })
        .then(res=>{
           setVariants(res.data)
           setLoading(false)
        })
        .catch(err=>{
            console.log(err.response);
           Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Une erreur est survenue !',
             })  
           })
    }

    

return (
    <>

        <div className="md:pl-8">
            <div className="container flex flex-col md:flex-row  max-w-full">
                <div className="px-4 md:w-1/3 mb-2 md:mb-16">
                    <Card>
                    <CardBody className={'flex flex-col w-full justify-center  mb-5'}>
                    <p className="text-gray-700 text-2xl mb-10">Liste des restaurants</p>

                    <table className="table w-full">
                        <thead>
                            <tr>
                            <th className=" text-gray-700 font-sofiapro align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left w-1/2">Nom</th>
                            <th className=" text-gray-700 font-sofiapro align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left w-1/4">Numéro de téléphone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entreprises && entreprises.map((item,index)=>(
                                <tr key={index} onClick={()=>{
                                    getVariants(item.id)
                                }} className={"hover:bg-gray-200 cursor-pointer" }>
                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left w-1/2">{(item.firstName || item.lastName   ) ? item.firstName+" "+ item.lastName : "-----" }</td>
                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left w-1/4">{item.phoneNumber}</td>
                                </tr>
                            ))}
                            
                            
                        </tbody>
                        </table>
                  </CardBody>
                  </Card>
                </div>
                <div className="md:w-2/3 px-4 mb-16">
                    {selectedEntreprise ? 
                    
                    variants && !loading ?
                    
                    <Card>
                    <CardBody>
                        <div className={'flex justify-between  mb-5'}>
                            <div>
                                <h2 className="text-gray-700 text-2xl">Liste des prix-variants</h2>
                                <p className="font-medium text-snack-pink-original">Double cliquez sur le prix du desert pour le changer</p>

                            </div>
                            </div>
                
                <div className="overflow-x-auto">
                    {/* <TableCard COLUMNS={COLUMNS_PRICES} DATA={variants} title={"Prix des variants"}  /> */}
                    <Table
                      columns={columns}
                      data={variants}
                      updateMyData={updateMyData}
                      //skipPageReset={skipPageReset}
                    />
                    </div>
            </CardBody>
        </Card >   
                      
                   : 
                        <Card>
                            <CardBody className={'flex w-full justify-center  mb-5'}>
                                <Loader
                                type="ThreeDots"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                            </CardBody>
                        </Card>
                    : 
                        <Card>
                        <CardBody className={'flex w-full justify-center  mb-5'}>
                                <p className="font-medium text-snack-pink-original">Veuillez sélectionner le restaurant pour gérer le menu</p>
                        </CardBody>
                        </Card> 
                  }
                </div>
            </div>
           </div >
    </>
)
} 
export default PrixRestaurants