import { useEffect, useState } from "react";


function OrderDetails({ item, detailsIngredients }) {
    const [orderIngs, setOrderIngs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let finalShown = [];
        console.log(item.toppings);
        if (detailsIngredients && item.toppings[0]) {

            detailsIngredients.forEach(element => {
                item.toppings[0].forEach(itemIng => {
                    console.log(element.id === itemIng.id);

                    if (itemIng.id === element.id) {
                        finalShown.push(element.label)
                    }
                })
            });
            finalShown.forEach(element => {
                console.log("final array", element);
            });
            setOrderIngs(finalShown);
        }else{
            setOrderIngs([]);
        }

    }, [item]);

    return (

        <tr>
            <td rowSpan={item.toppings.length} className=" w-2/6 px-4">
                <h3 className="text-base text-snack-gray-dark  font-sofiapro">
                    {item.variant.title}
                </h3>
            </td>
            <td rowSpan={item.toppings.length} className=" w-2/12 px-4">
                <p className="text-base font-medium text-snack-gray-dark font-sofiapro">{item.amount}</p>
            </td>
            <td rowSpan={item.toppings.length} className=" w-2/12 px-4">

                {(orderIngs && orderIngs.length > 0) ? orderIngs.map((element, index) => (
                    <tr key={index}>
                        <p className="text-base font-medium text--snack-gray-dark font-sofiapro">+ {element}</p>
                    </tr>
                )) : null}


            </td>
        </tr>
    );
}
export default OrderDetails;