import React, { useCallback, useState, useEffect} from 'react'
import { Button, Icon, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import Swal from 'sweetalert2'
import Switch from "react-switch";

const Users = () => {
    const [showADDModal, setShowADDModal] = useState(false);
    const [showUPDATEModal, setShowUpdateModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [user_id_block, setUserBlock] = useState(null);
    const [row_id_block, setRowBlock] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(null)

    
    const COLUMNS = [
        {
            Header: 'Id',
            accessor: 'id',
        },
        {
            Header: 'Nom et prénom',
            accessor: 'firstName',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{(row.original.firstName || row.original.lastName   ) ? row.original.firstName+" "+ row.original.lastName : "-----" }</p>
                    
                </div>),
        },
        {
            Header: 'Numéro de téléphone',
            accessor: 'phoneNumber',
            
        },
        {
            Header: 'Email',
            accessor: 'email',
            
        },
        {
            Header: 'Date de naissance',
            accessor: 'birthdate',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.birthdate).toLocaleDateString()}</p>
                    
                </div>),
        },
        {
            Header: 'Sexe',
            accessor: 'gender',
            
        },
        {
            Header: 'Code de recommandation',
            accessor: 'inviteCode',
            
        },
        {
            Header: 'Active',
            accessor: 'isBlocked',
            Cell: ({ row }) => (
                <div>
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${row.original.isBlocked ? 'bg-snack-pink-original text-pink-900' :   'bg-green-100 text-green-800'}  }  `}>
                        {row.original.isBlocked ? "Inactive" : "Active"}
                    </span>
                    <p className={`$`}></p>

                </div>),
        },
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title='Modifier' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        if(row.original.firstName || row.original.lastName ){
                        setName(row.original.firstName+" "+row.original.lastName)}else{
                            setName('Non définie');
                        }
                        setEmail(row.original.email)
                        setUserBlock(row.original.id)
                        setRowBlock(row.id)
                        setStatus(!row.original.isBlocked)
                        setShowUpdateModal(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    
                </div>),
        }
    ]

   

    useEffect(() => {
       axios.get('/users?isEntreprise=0')
        .then(res=>{
            setUsers(res.data.reverse());
        })
        .catch(err=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de récupération est survenue !',
              })  
        })
    }, [])


    const BlockUser = () => {
        
        axios.patch(`users/${user_id_block}`, {"isBlocked":!status}, {headers:{"content-type": "application/merge-patch+json"}})
        .then(res=>{
            Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Utilisateur bloqué avec succès!',
                showConfirmButton: false,
                timer: 1500
            })
            setShowUpdateModal(false)
            setUserBlock(null)
            axios.get('/users?isEntreprise=0')
            .then(res=>{
                setUsers(res.data);
            })
            .catch(err=>{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur de récupération est survenue !',
                  })  
            })
        })
        .catch(err=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de mise à jours est survenue !',
              })  
        })
    }
  

    return (
        <>

            <div className="md:pl-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard onClick={setShowUpdateModal} COLUMNS={COLUMNS} DATA={users}
                        description={"Cliquez sur le nom d'une colonne pour trier les données."} title={"Liste des utilisateurs"} />
                    </div>
                </div>
               </div >
            <Modal size="lg" active={showUPDATEModal} toggler={() => setShowUpdateModal(false)}>
                <ModalHeader toggler={() => setShowUpdateModal(false)}>
                    Modifier le status 
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12 ">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                <div className="flex ">
                                    <div className="mb-4 mr-10">
                                        
                                        <Input
                                            value={name}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            disabled
                                            placeholder="Nom complet"
                                        />
                                    </div>
                                    <div className="mb-4 w-9/12">
                                        
                                        <Input
                                            value={email}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            disabled
                                            placeholder="E-mail"
                                        />
                                    </div>
                                </div>
                                    <div >
                                            <label className="leading-loose mr-10">Bloquer l'utilisateur</label>
                                            <Switch
                                                checked={status ? status : false}
                                                onChange={(checked) => setStatus(checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={15}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={25}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </div>

                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={()=>setShowUpdateModal(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>

                    <Button
                        color="green"
                        onClick={BlockUser}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>
                </ModalFooter>
            </Modal >

        </>
    );
}
export default Users