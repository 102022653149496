import { Switch, Route, Redirect, BrowserRouter as Router, } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import Dashboard from 'components/screens/Dashboard';
import Settings from 'components/childComponents/Settings';
import Tables from 'components/childComponents/Tables';
import Login from 'components/screens/Login';

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';
import Desserts from 'components/screens/Desserts';
import Occasions from 'components/screens/Occasions';
import Footer from 'components/Footer';
import Ingredients from 'components/screens/Ingredients';
import Commandes from 'components/screens/Commandes';
import Variants from 'components/screens/Variants';
import Users from 'components/screens/Users';
import {  useDispatch, useSelector } from 'react-redux';
import { selectUserId } from 'redux/features/authSlice';
import { useState,useEffect } from 'react';
import { selectUserEmail } from 'redux/features/authSlice';
import axios from 'axios';
import Swal from 'sweetalert2';
import Restaurants from 'components/screens/Restaurants';
import PrixRestaurants from 'components/screens/PrixRestaurants';
import CommandesRestaurants from 'components/screens/CommandesRestaurants';
import Pots from 'components/screens/Pots';
import { loginUser } from 'redux/features/authSlice';
import Invoices from 'components/screens/Invoices';
import PrixPotsRestaurants from 'components/screens/PrixPotsRestaurants';


const App = () => {
    
    const userId = useSelector(selectUserId);
    const userMail = useSelector(selectUserEmail);
    const dispatch = useDispatch();

    useEffect(() => {
        if(userId!==0)
        {//http://127.0.0.1:8000/api/admins/
            axios.get(`https://api-dashboard.snack-time.fr/api/admins/${userId}`)
        .then(res=>{
            if(res.data.email !== userMail)
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Attention !',
                    text: 'Session expirée',
                    confirmButtonText: 'ok',
                  }).then((result)=>{
                    dispatch(loginUser({
                        id : 0,
                        email : "",
                        roles : []
                       }))
                  })  
                
            }
        })
        .catch(err=> {
            
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
                confirmButtonText: 'ok',
            })/* .then((result)=>{
              dispatch(loginUser({
                  id : 0,
                  email : "",
                  roles : []
                 }))
            })         */
        })

    }
    }, [userId,userMail])

    
    return (
        <Router>
            { userId===0? 
             <Login/>
             : 
            <div className={'h-screen flex flex-col'}>
                <div className={'flex-1 bg-gradient-to-tl from-snack-pink-light to-snack-green-light'}>
                    <Sidebar />
                    <div className="md:ml-64">

                        <Switch>
                            <Route exact path="/dashboard" component={Dashboard} />
                            <Route exact path="/occasions" component={Occasions} />
                            <Route exact path="/desserts" component={Desserts} />
                            <Route exact path="/pots" component={Pots} />
                            <Route exact path="/ingredients" component={Ingredients} />
                            <Route exact path="/variants" component={Variants} />
                            <Route exact path="/commandes" component={Commandes} />
                            <Route exact path="/settings" component={Settings} />
                            <Route exact path="/tables" component={Tables} />
                            <Route exact path="/users" component={Users} />
                            <Route exact path="/factures" component={Invoices} />
                            <Route exact path="/restaurants" component={Restaurants} />
                            <Route exact path="/prix-restaurants" component={PrixPotsRestaurants} />
                            <Route exact path="/commandes-restaurants" component={CommandesRestaurants} />
                            <Redirect from="*" to="/dashboard" />

                        </Switch>

                        <Footer />
                    </div>
                </div>
            </div>
            }
        </Router>
    );
}

export default App;
