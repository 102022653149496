import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Icon, Image, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select';
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'


const Desserts = () => {

    const [showModal, setShowModal] = useState(false);
    const [showModalt, setShowModalt] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);

    //add
    const [desserts, setDesserts] = useState([]);
    const [label, setLabel] = useState('');
    const [summary, setSummary] = useState('');
    const [packaging, setPackaging] = useState(''); //[ unit, pack ]
    const [packagingAmount, setPackagingAmount] = useState('');

    //update
    const [dessert_id_update, setDessertUpdate] = useState(0);
    const [name, setName] = useState('');
    const [summaryUp, setSummaryUp] = useState('');
    const [packagingUp, setPackagingUp] = useState(''); //[ unit, pack ]
    const [packagingAmountUp, setPackagingAmountUp] = useState(0);

    const [file, setFile] = useState(null);
    const MySwal = withReactContent(Swal)
    
    let packagingOptions = [
        {value:"pack",label:"Par paquet"},
        {value:"unit",label:"Par unité"}
        ]
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFile(acceptedFiles[0]);
    }, [])
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file ? file.path : ""} - {file.size} bytes
        </li>
    ));
    var count = 35; //to cut the long summary on table

  
    const COLUMNS = [
        {
            Header: 'Identifiant',
            accessor: 'id',
        },
        {
            Header: 'Image',
            accessor: 'cover',
            Cell: ({ row }) => (
                <div style={{display:"flex",justifyContent:"center"}}>
                    {
                    row.original.cover ? row.original.cover.filename &&
                    <div> 
                        <Image
                    style={{
                        height:'auto',
                        width:50,
                        
                    }}//http://127.0.0.1:8000
                    src={"https://api-dashboard.snack-time.fr/images/uploaded/"+row.original.cover.filename}
                    alt="dessert snacktime"/>
                     <button className='text-green-400 hover:text-gray-300 mr-2 ml-10' onClick={() => {setDessertUpdate(row.original.id);setShowImageModal(true);}}>
                    <Icon name="flip_camera_ios" size="2xl" />
                    </button>
                    </div>
                    : null }
                    
                </div>),
        },
        {
            Header: 'Nom',
            accessor: 'label',
        },
        {
            Header: 'Description',
            accessor: 'summary',
            Cell: ({ row }) => (
                <div className="w-60">
                    <p className="text-gray">{row.original.summary.slice(0, count) + (row.original.summary.length > count ? "..." : "")}</p>
                </div>),
        },
        {
            Header: 'Unité',
            accessor: 'packaging',
        },
        {
            Header: 'Nombre de pièces',
            accessor: 'packagingAmount',
        },
        /* {
            Header: 'Date Création',
            accessor: 'createdAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.createdAt).toLocaleDateString()}</p>
                </div>),
        },
        {
            Header: 'Date Modification',
            accessor: 'updatedAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.updatedAt).toLocaleDateString()}</p>
                    
                </div>),
        }, */
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title="Modifier" className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        setDessertUpdate(row.original.id)
                        setName(row.original.label)
                        setSummaryUp(row.original.summary)
                        
                        packagingOptions.map((item)=>{
                            if(item.value===row.original.packaging){
                                setPackagingUp(item)
                            }
                        })
                        setPackagingAmountUp(row.original.packagingAmount)
                        setShowModalt(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    <button title="Supprimer" className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => openAlert(row.original.id)/*deleteDesserts(row.original.id,row.id)*/ }
                    ><Icon name="delete" size="2xl" /></button>
                   
                </div>),
        }
    ]

    const updateImDes = () => {
        const formData = new FormData();
  
        // Update the formData object
        formData.append(
          "imageFile",
          file,
        );
        let obj;
        axios.post("photos", formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then(res=>{
            obj= {
            "createdAt":new Date(),
            "updatedAt":new Date(),
            "cover":res.data["@id"],
            }
            
            axios.patch(`desserts/${dessert_id_update}`, obj, {headers:{"content-type": "application/merge-patch+json"}})
            .then(res=>{
                setShowImageModal(false);
                setFile(null);
                axios.get('/desserts')
        .then(res=>{
            setDesserts(res.data);
        })
        .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
        });
                Swal.fire({
                    toast:true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Image modifiée avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                  })
            })
            .catch(err=>{
                console.log('update dessert im :::', err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Une erreur d'ajout de photo est survenue !",
                  }) 
            })
        
        })
        .catch(err=>{
            console.log('update occ im :::', err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Une erreur d'ajout de photo est survenue !",
              }) 
        })
    }


    useEffect(() => {
        axios.get('/desserts')
         .then(res=>{
             setDesserts(res.data);
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
         })
     }, [])

      
  const updateDessert = () => {
    
    let body = {
        "label": name,
        "summary": summaryUp,
        "packaging": packagingUp.value,
        "packagingAmount": parseInt(packagingAmountUp),
        "updatedAt": new Date()
        
    }

    axios.patch(`desserts/${dessert_id_update}`, body, {headers:{"content-type": "application/merge-patch+json"}})
    .then(res=>{
        
        Swal.fire({
            toast:true,
            position: 'top-end',
            icon: 'success',
            title: 'Dessert modifié avec succès!',
            showConfirmButton: false,
            timer: 1500
        })
        setShowModalt(false)
        
        axios.get('/desserts')
         .then(res=>{
             setDesserts(res.data);
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
         })
    })
    .catch(err=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur de mise à jours!',
          })  
    }) 
}


     
  const postDesserts = () => {
    
    // Create an object of formData
    const formData = new FormData();
  
    // Update the formData object
    formData.append(
      "imageFile",
      file,
    );
    formData.append("label",label)
    formData.append("summary",summary)
    formData.append("packaging",packaging.value)
    formData.append("packagingAmount",packagingAmount)

    axios.post("add/dessert/photo", formData, {headers:{"Content-Type": "multipart/form-data"}})
      .then(res=>{
          setLabel("");
          setSummary("");
          setPackaging("");
          setPackagingAmount("");
        Swal.fire({
            toast:true,
            position: 'top-end',
            icon: 'success',
            title: 'Dessert ajouté avec succès!',
            showConfirmButton: false,
            timer: 1500
          })
            setDesserts([...desserts, res.data]);
            setShowModal(false);

      })
      .catch(error=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Une erreur d'ajout est survenue !",
          })  
      })
  };
  
  const openAlert = (delete_id) => {
    MySwal.fire({
        title: <p>Hello World</p>,
        footer: 'Copyright 2018',
        icon: 'question',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal`
          //   with all the same instance & static methods
          MySwal.clickConfirm()
        }
      }).then(() => {
        return Swal.fire({
            title: 'Etes-vous sur de vouloir effacer cette famille de desserts?',
            text: "Cette action n'est pas reversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
          }).then((result) => {
            if (result.isConfirmed) {
                deleteDesserts(delete_id)
              Swal.fire(
                'Succès de suppression!',
                'Votre dessert a été supprimé.',
                'success'
              )
            }
          })
      })
  }


  const deleteDesserts = (delete_id) => {
    axios.delete(`desserts/${delete_id}`)
    .then(res=>{
          setDesserts(desserts.filter((item)=>item.id!==delete_id));
          Swal.fire({
            toast:true,
            position: 'top-end',
            icon: 'success',
            title: 'Famille de dessert supprimé avec succès!',
            showConfirmButton: false,
            timer: 1500
          })

    })
    .catch(error=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur de supression est survenue !',
          })  
    })
  }
    return (
        <>

            <div className="md:pl-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard onClick={setShowModal} COLUMNS={COLUMNS} title="Gestion des familles de desserts" description={"Une famille de desserts est la catégorie parente d'un dessert. Chaque dessert appartient obligatoirement à une famille."} DATA={desserts} />
                    </div>
                </div>
                <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
                    <ModalHeader toggler={() => setShowModal(false)}>
                        Ajouter une famille de dessert
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={label}
                                                onChange={(e)=>setLabel(e.target.value)}
                                                placeholder="Nom"
                                            />
                                        {!label && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}

                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                
                                                outline={true}
                                                value={summary}
                                                onChange={(e)=>setSummary(e.target.value)}
                                                placeholder="Description"
                                            />
                                        {!summary && <p className="text-snack-pink-original font-normal text-sm">Description obligatoire</p>}

                                        </div>
                                        <div className="mb-4">
                                        <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>
                                                        setPackaging(selectedOption)}
                                                    value={packaging}
                                                    options={packagingOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Unité de vente"
                                                    isClearable={true} />
                                        {!packaging && <p className="text-snack-pink-original font-normal text-sm">Packaging obligatoire</p>}         
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={packagingAmount}
                                                outline={true}
                                                onChange={(e)=>setPackagingAmount(e.target.value)}
                                                placeholder="Nombre de pièces"
                                            />
                                        {!packagingAmount && <p className="text-snack-pink-original font-normal text-sm">Nombre de pièces obligatoire</p>}
                                        </div>


                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Photo</label>
                                            <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700' : 'border-gray-300'}  border-dashed rounded-md`}>
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            {
                                                                isDragActive && files !== '' ?
                                                                    null :
                                                                    <span>Importer</span>
                                                            }

                                                            <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        {
                                                            isDragActive ?
                                                                <p className="pl-1">Drop the files here ...</p> :
                                                                <p className="pl-1">ou glissez un fichier ici</p>
                                                        }

                                                    </div>
                                                    {files !== '' ? <ul>{files}</ul> : <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>}


                                                </div>
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e) => setShowModal(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>
                       
                        {(label && summary && packaging && packagingAmount) ? 
                        <Button
                            color="green"
                            onClick={postDesserts}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> 
                        : 
                        <Button
                            disabled
                            color="gray"
                            onClick={postDesserts}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> 
                        }
                    </ModalFooter>
                </Modal >
                <Modal size="lg" active={showImageModal} toggler={() => setShowImageModal(false)}>
                    <ModalHeader toggler={() => setShowImageModal(false)}>
                        Modifier l'image
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                        
                                        
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Photo</label>
                                            <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700' : 'border-gray-300'}  border-dashed rounded-md`}>
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            {
                                                                isDragActive && files !== '' ?
                                                                    null :
                                                                    <span>Importer</span>
                                                            }

                                                            <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        {
                                                            isDragActive ?
                                                                <p className="pl-1">Drop the files here ...</p> :
                                                                <p className="pl-1">ou glissez un fichier ici</p>
                                                        }

                                                    </div>
                                                    {files !== '' ? <ul>{files}</ul> : <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>}


                                                </div>
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e)=>setShowImageModal(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>

                        { file ? <Button
                            
                            color=  "green"
                            onClick={updateImDes}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> : <Button
                            disabled
                            color=  "gray"
                            onClick={updateImDes}
                            ripple="light"
                        >
                            Enregistrer
                        </Button>}
                    </ModalFooter>
                </Modal >
            </div >
            <Modal size="lg" active={showModalt} toggler={() => setShowModalt(false)}>
                <ModalHeader toggler={() => setShowModalt(false)}>
                    Mettre à jours une famille de desserts
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                    <div className="mb-4">
                                        <Input
                                            value={name}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setName(e.target.value);
                                            }}
                                            placeholder="Nom"
                                        />
                                        {!name && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}
                                    </div>
                                    <div className="mb-4">
                                        <Input
                                            value={summaryUp}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setSummaryUp(e.target.value);
                                            }}
                                            placeholder="Description"
                                        />
                                        {!summaryUp && <p className="text-snack-pink-original font-normal text-sm">Description obligatoire</p>}
                                    </div>
                                    <div className="mb-4">
                                        <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>
                                                        setPackagingUp(selectedOption)}
                                                    value={packagingUp}
                                                    options={packagingOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Unité de vente"
                                                    isClearable={true} />
                                        {!packagingUp && <p className="text-snack-pink-original font-normal text-sm">Packaging type obligatoire</p>}
                                    </div>
                                    <div className="mb-4">
                                        <Input
                                            value={packagingAmountUp}
                                            
                                            color="lightBlue"
                                            size="regular"
                                            type="number"
                                            outline={true}
                                            onChange={e => {
                                                setPackagingAmountUp(e.target.value);
                                            }}
                                            placeholder="Nombre de pièces"
                                        />
                                        {!packagingAmountUp && <p className="text-snack-pink-original font-normal text-sm">Nombre de pièces obligatoire</p>}
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModalt(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    {(name && summaryUp && packagingUp && packagingAmountUp) ? 
                    <Button
                        color="green"
                        onClick={updateDessert}
                        ripple="light"
                    >
                        Enregistrer
                    </Button> 
                    :
                    <Button
                        disabled
                        color="gray"
                        onClick={updateDessert}
                        ripple="light"
                    >
                        Enregistrer
                    </Button> 
                    }
                </ModalFooter>
            </Modal >

        </>
    );
}
export default Desserts