import React, {  useState, useEffect } from 'react'
import { Button, Icon, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select';
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'

const Ingredients = () => {
    const [ingredients, setIngredients] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalt, setShowModalt] = useState(false);
    const [toUpdate, setToUpdate] = useState(0)
    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')
    const [add_price, setAddPrice] = useState('')
    const [price, setPrice] = useState('')
    const [type, setType] = useState(null)
    const [label, setLabel] = useState('');
    const [slugAdd, setSlugAdd] = useState('');
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        axios.get('/ingredients')
         .then(res=>{
             setIngredients((res.data).reverse());
         })
         .catch(err=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de supression est survenue !',
              }) 
         })
     }, [])
    let typeOptions = [
        {value:"garnitures",label:"Garnitures"},
        {value:"noix",label:"Noix"},
        {value:"sauces et purées",label:"Sauces et purées"},
        {value:"fruits frais",label:"Fruits frais"},
        {value:"saupoudrage",label:"Saupoudrage"},
        ]
    const COLUMNS = [
        {
            Header: 'Identifiant',
            accessor: 'id',
        }, 
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Nom',
            accessor: 'label',
        },
        
        {
            Header: 'Prix',
            accessor: 'price',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.price.toFixed(2)}€</p>
                </div>),
        },
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title='Modifier' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        setName(row.original.label)
                        setSlug(row.original.slug)
                        setPrice(row.original.price)
                        setType(typeOptions.filter(item=>item.value===row.original.type) )
                        setToUpdate(row.original.id)
                        setShowModalt(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    <button title='Supprimer' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => openAlert(row.original.id)}
                    ><Icon name="delete" size="2xl"/></button>
                </div>),
        }
    ]

    const postIngrédients = () =>{
        let body 
        if(add_price){
        body = {
            "label": label,
            "slug": slugAdd,
            "price": Number.parseFloat(add_price),
            "type": type.value,
            "variant": []
          }
        }else {
            body = {
                "label": label,
                "slug": slugAdd,
                "type": type.value,
                "variant": []
              }
        }
          axios.post("ingredients", body)
            .then(res=>{
                setLabel("");
                setSlugAdd("");
                setAddPrice("");
                setType(null);
                Swal.fire({
                    toast:true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Ingrédient ajouté avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                  })
                    setIngredients([res.data, ...ingredients]);
                    setShowModal(false);

            })
            .catch(error=>{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Une erreur d'ajout est survenue !",
                  }) 
            })
    }

    const openAlert = (delete_id) => {
        MySwal.fire({
            didOpen: () => {
              // `MySwal` is a subclass of `Swal`
              //   with all the same instance & static methods
              MySwal.clickConfirm()
            }
          }).then(() => {
            return Swal.fire({
                title: 'Etes-vous sur de vouloir effacer cet ingrédient?',
                text: "Cette action n'est pas reversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Supprimer',
                cancelButtonText: 'Annuler'
              }).then((result) => {
                if (result.isConfirmed) {
                    deleteIngredients(delete_id)
                  Swal.fire(
                    'Succès de suppression!',
                    'Votre Ingrédient a été supprimé.',
                    'success'
                  )
                }
              })
          })
      }
    
    
      const deleteIngredients = (delete_id) => {
        axios.delete(`ingredients/${delete_id}`)
        .then(res=>{
              setIngredients(ingredients.filter((item)=>item.id!==delete_id));
              Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Ingrédient supprimé avec succès!',
                showConfirmButton: false,
                timer: 1500
              })
    
        })
        .catch(error=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de supression est survenue !',
              }) 
        })
      }

      const updateIngredients = (update_id) => {
          let body
          if(price){
          body ={
            "label": name,
            "slug": slug,
            "type": type.value,
            "price":  Number.parseFloat(price) 
          }
        }else{
            body ={
                "label": name,
                "slug": slug,
                "type": type.value,
              }
        }
        axios.patch(`ingredients/${update_id}`, body, {headers:{"content-type": "application/merge-patch+json"}})
        .then(res=>{
                setSlug("");
                setName("");
                setPrice("");
                setType(null)
                setShowModalt(false)
                axios.get('/ingredients')
                .then(res=>{
                    setIngredients((res.data).reverse());
                })
                .catch(err=>{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur de modification est survenue !',
                      }) 
                })
              Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Ingrédient modifié avec succès!',
                showConfirmButton: false,
                timer: 1500
              })
    
        })
        .catch(error=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de modification est survenue !',
              }) 
        })
      }


    return (
        <>

            <div className="md:pl-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard onClick={setShowModal} COLUMNS={COLUMNS} DATA={ingredients}
                        description={"Cliquez sur le nom d'une colonne pour trier les données."} title="Gestion des ingrédients" />
                    </div>
                </div>
                <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
                    <ModalHeader toggler={() => setShowModal(false)}>
                        Ajouter ingrédient
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={label}
                                                onChange={(e)=>setLabel(e.target.value)}
                                                placeholder="Nom"
                                            />
                                            {!label && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}
                                        
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={slugAdd}
                                                onChange={(e)=>setSlugAdd(e.target.value)}
                                                placeholder="Slug"
                                            />
                                            {!slugAdd && <p className="text-snack-pink-original font-normal text-sm">Slug obligatoire</p>}
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={add_price}
                                                onChange={(e)=>setAddPrice(e.target.value)}
                                                placeholder="Prix"
                                            />
                                        </div>
                                        <div className="mb-4">
                                        <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>
                                                        setType(selectedOption)}
                                                    value={type}
                                                    options={typeOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Type de topping"
                                                    isClearable={true} />
                                        {!type && <p className="text-snack-pink-original font-normal text-sm">Type du topping obligatoire</p>}         
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e) => setShowModal(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>
                        { label && slugAdd && type ? 
                        <Button
                            color="green"
                            onClick={postIngrédients}
                            ripple="light"
                        >
                            Enregistrer
                        </Button>: 
                        <Button
                        disabled
                        color="gray"
                        onClick={postIngrédients}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>}
                    </ModalFooter>
                </Modal >
            </div >

            <Modal size="lg" active={showModalt} toggler={() => setShowModalt(false)}>
                <ModalHeader toggler={() => setShowModalt(false)}>
                    Modifier ingrédient
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                    <div className="mb-4">
                                        
                                        <Input
                                            value={name}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setName(e.target.value);
                                            }}
                                            placeholder="Nom"
                                        />
                                    {!name && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}

                                    </div>
                                    <div className="mb-4">
                                        
                                        <Input
                                            value={slug}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setSlug(e.target.value);
                                            }}
                                            placeholder="Slug"
                                        />
                                    {!slug && <p className="text-snack-pink-original font-normal text-sm">Slug obligatoire</p>}

                                    </div>
                                    <div className="mb-4">
                                        
                                        <Input
                                            value={price}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            onChange={e => {
                                                setPrice(e.target.value);
                                            }}
                                            placeholder="Prix"
                                        />

                                    </div>
                                    <div className="mb-4">
                                        <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>
                                                        setType(selectedOption)}
                                                    value={type}
                                                    options={typeOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Type de topping"
                                                    isClearable={true} />
                                        {!type && <p className="text-snack-pink-original font-normal text-sm">Type du topping obligatoire</p>}         
                                        </div>

                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModalt(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    {name && slug  && type ? 
                    <Button
                        color="green"
                        onClick={()=>updateIngredients(toUpdate)}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>: 
                    <Button
                    disabled
                    color="gray"
                    onClick={()=>updateIngredients(toUpdate)}
                    ripple="light"
                >
                    Enregistrer
                </Button>}
                </ModalFooter>
            </Modal >

        </>
    );
}
export default Ingredients