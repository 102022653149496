import React from 'react'

export default function Loading(rows) {
    const list = []

    for (let index = 0; index < rows.rows; index++) {
        list.push(<th key={index} className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            <div className="h-4 bg-gray-200 mt-3 mb-6 rounded"></div>
        </th>)
    }

    return (
        <>
            <tr className="animate-pulse">
                {list}
            </tr>
            <tr className="animate-pulse">
                {list}
            </tr>
            <tr className="animate-pulse">
                {list}
            </tr>
        </>
    )
}
