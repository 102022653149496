import React, { useEffect, useState } from 'react'
import { Button, Icon, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import Select from 'react-select';
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'
import Swal from 'sweetalert2';
import Switch from "react-switch";

//var bcrypt = require('bcryptjs');

const Restaurants = () => {
    const [showADDModal, setShowADDModal] = useState(false);
    const [showUPDATEModal, setShowUpdateModal] = useState(false);
    const [isMonthly, setIsMonthly] = useState(false);
    const [entreprises, setEntreprises] = useState([]);
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pass, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [updatedEntreprise, setUpdatedEntreprise] = useState(null);

    useEffect(() => {
        
        axios.get('/users?isEntreprise=1')
         .then(res=>{
            setEntreprises(res.data.reverse());
            
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
            })
         
    }, [])
    const COLUMNS = [
        {
            Header: 'Identifiant',
            accessor: 'id',
        },
        {
            Header: 'Nom et prénom',
            accessor: 'firstName',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{(row.original.firstName || row.original.lastName   ) ? row.original.firstName+" "+ row.original.lastName : "-----" }</p>
                    
                </div>),
        },
        {
            Header: 'Numéro de téléphone',
            accessor: 'phoneNumber',
            
        },
        {
            Header: 'Email',
            accessor: 'email',
            
        },
        {
            Header: 'Active',
            accessor: 'isBlocked',
            Cell: ({ row }) => (
                <div>
                    <Switch
                                                checked={row.original.isBlocked ? !row.original.isBlocked : true}
                                                onChange={(checked) => {
                                                    
                                                    axios.patch(`users/${row.original.id}`, {"isBlocked":!checked}, {headers:{"content-type": "application/merge-patch+json"}})
                                                    .then(res=>{
                                                        Swal.fire({
                                                            toast:true,
                                                            position: 'top-end',
                                                            icon: 'success',
                                                            title: 'Status modifiée avec succès!',
                                                            showConfirmButton: false,
                                                            timer: 1500
                                                        })
                                                        //setShowUpdateModal(false)
                                                        

                                                        
                                                    })
                                                    .catch(err=>{
                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Oops...',
                                                            text: 'Une erreur de mise à jours est survenue !',
                                                        })  
                                                    })
                                                    entreprises.map((item,index)=>{
                                                        if(item.id===row.original.id){
                                                            
                                                                let items = entreprises;
                                                                // 2. Make a shallow copy of the item you want to mutate
                                                                let item = items[index];
                                                                // 3. Replace the property you're intested in
                                                                item.isBlocked = !checked;
                                                                // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                                items[index] = item;
                                                                // 5. Set the state to our new copy
                                                                setEntreprises([...items]);
                                                                
                                                             
                                                        }
                                                    })
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={15}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={25}
                                                className="react-switch"
                                                id="material-switch"
                                            />

                </div>),
        },
        {
            Header: 'Abonnement Mensuel',
            accessor: 'monthlySubscribed',
            Cell: ({ row }) => (
                <div>
                    <Switch
                                                checked={row.original.monthlySubscribed ? row.original.monthlySubscribed : false}
                                                onChange={(checked) => {
                                                    
                                                    axios.patch(`users/${row.original.id}`, {"monthlySubscribed":checked}, {headers:{"content-type": "application/merge-patch+json"}})
                                                    .then(res=>{
                                                        Swal.fire({
                                                            toast:true,
                                                            position: 'top-end',
                                                            icon: 'success',
                                                            title: 'Abonnement modifiée avec succès!',
                                                            showConfirmButton: false,
                                                            timer: 1500
                                                        })
                                                        //setShowUpdateModal(false)
                                                        

                                                        
                                                    })
                                                    .catch(err=>{
                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Oops...',
                                                            text: 'Une erreur de mise à jours est survenue !',
                                                        })  
                                                    })
                                                    entreprises.map((item,index)=>{
                                                        if(item.id===row.original.id){
                                                            
                                                                let items = entreprises;
                                                                // 2. Make a shallow copy of the item you want to mutate
                                                                let item = items[index];
                                                                // 3. Replace the property you're intested in
                                                                item.monthlySubscribed = checked;
                                                                // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                                items[index] = item;
                                                                // 5. Set the state to our new copy
                                                                setEntreprises([...items]);
                                                                
                                                             
                                                        }
                                                    })
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={15}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={25}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                </div>),
        },
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title='Modifier' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        setName(row.original.firstName)
                        setLastName(row.original.lastName)
                        setEmail(row.original.email)
                        setPhoneNumber(row.original.phoneNumber)
                        setUpdatedEntreprise(row.original.id)
                        setShowUpdateModal(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    
                </div>),
        }
    ]
    const postEntreprise = () => {
        let body = {
            "firstName": name,
            "lastName": lastname,
            "phoneNumber": phoneNumber,
            "monthly_subscribed": isMonthly,
            "email": email,
            "invite_code": Math.random(0).toString(36).substring(2),
            "password":pass
        }
        axios.post('/user/entreprise', body)
         .then(res=>{
            setEntreprises([...entreprises, res.data]);
            setShowADDModal(false);
            setName("");
            setLastName("");
            setPhoneNumber("");
            setIsMonthly(false);
            setEmail("");
            setPassword("");

            axios.post("/contacts",{
                "isDelivery": true,
                "isOrder": false,
                "User": {"id":res.data.id}
              })
              .catch((fail)=>{
                console.log(fail.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                  }) 
              }) 
            axios.post("/contacts",{
                "isDelivery": false,
                "isOrder": true,
                "User": {"id": res.data.id}
              })
              .catch((fail)=>{
                console.log(fail.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                  }) 
              })
              Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Entreprise ajoutée avec succès!',
                showConfirmButton: false,
                timer: 1500
              })
                     })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Cet e-mail peut etre déjà utilisé. Merci de vérifier vos informations!',
              })  
            })
        /*
         bcrypt.genSalt(10, function(err, salt) {
            bcrypt.hash(pass, salt, function(err, hash) {
                // Store hash in your password DB.
                console.log("hashed :::", hash);
            });
        }); */
    }


    const UpdateEntreprise = () => {
        let body = {
            "firstName": name,
            "lastName": lastname,
            "phoneNumber": phoneNumber,
            "email": email,
            "monthly_subscribed": isMonthly,
            
        }
        
        axios.patch(`users/${updatedEntreprise}`, body, {headers:{"content-type": "application/merge-patch+json"}})
        .then(res=>{
            Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Entreprise bloquée avec succès!',
                showConfirmButton: false,
                timer: 1500
            })
            setShowUpdateModal(false)
            setName("");
            setLastName("");
            setEmail("");
            setPhoneNumber("");
            setPassword("");
            setIsMonthly(false);
            axios.get('/users?&isEntreprise=1')
            .then(res=>{
                setEntreprises(res.data);
            })
            .catch(err=>{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur de récupération est survenue !',
                  })  
            })
        })
        .catch(err=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de mise à jours est survenue !',
              })  
        })
    }
    return (
        <>

        <div className="md:pl-8">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 px-4 mb-16">
                    <TableCard onClick={setShowADDModal} COLUMNS={COLUMNS} DATA={entreprises}
                    description={"Cliquez sur le nom d'une colonne pour trier les données."} title={"Liste des restaurants"} />
                </div>
            </div>
           </div >
           <Modal size="lg" active={showADDModal} toggler={() => setShowADDModal(false)}>
                    <ModalHeader toggler={() => {
                        setShowADDModal(false);
                        setName("");
                        setLastName("");
                        setPhoneNumber("");
                        setIsMonthly(false);
                        setEmail("");
                        setPassword("");
                        }}>
                        Ajouter un compte restaurant
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={name}
                                                onChange={(e)=>setName(e.target.value)}
                                                placeholder="Nom"
                                            />
                                            {!name && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}
                                        
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={lastname}
                                                onChange={(e)=>setLastName(e.target.value)}
                                                placeholder="Prénom"
                                            />
                                            {!lastname && <p className="text-snack-pink-original font-normal text-sm">Prénom obligatoire</p>}
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={phoneNumber}
                                                onChange={(e)=>{ 
                                                    if((e.target.value.match("^[0-9]*$") != null)&&(e.target.value.length<=10)){
                                                    setPhoneNumber(e.target.value)
                                                }}
                                                }
                                                placeholder="Numéro de téléphone"
                                            />
                                            {(phoneNumber.length<10) && <p className="text-snack-pink-original font-normal text-sm">Numéro de téléphone de 10 chiffres est obligatoire</p>}
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="email"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={email}
                                                onChange={(e)=>setEmail(e.target.value)}
                                                placeholder="E-mail"
                                            />
                                            {(email.length<1 || !email.match("^[A-Za-z]+[0-9._%+-]*[A-Za-z]*@[A-Za-z]+\.[A-Za-z]+$")) && <p className="text-snack-pink-original font-normal text-sm">E-mail valide obligatoire</p>}
                                        </div>
                                        <div className="mb-4">
                                            
                                            <Input
                                                
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                value={pass}
                                                type="password"
                                                onChange={(e)=>setPassword(e.target.value)}
                                                placeholder="Mot de passe"
                                            />
                                            {pass.length<8 && <p className="text-snack-pink-original font-normal text-sm">Mot de passe obligatoire de longueur minimale égale à 8</p>}
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e) => {
                                setShowADDModal(false);
                                setName("");
                                setLastName("");
                                setPhoneNumber("");
                                setIsMonthly(false);
                                setEmail("");
                                setPassword("");
                            }}
                            ripple="dark"
                        >
                            Fermer
                        </Button>
                        { name && lastname && email.match("^[A-Za-z]+[0-9._%+-]*[A-Za-z]*@[A-Za-z]+\.[A-Za-z]+$") && phoneNumber.length ==10 && pass.length>=8 ? 
                        <Button
                            color="green"
                            onClick={postEntreprise}
                            ripple="light"
                        >
                            Enregistrer
                        </Button>: 
                        <Button
                        disabled
                        color="gray"
                        onClick={postEntreprise}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>}
                    </ModalFooter>
                </Modal >

                <Modal size="lg" active={showUPDATEModal} toggler={() => setShowUpdateModal(false)}>
                <ModalHeader toggler={() => {
                    setShowUpdateModal(false);
                    setName("");
                    setLastName("");
                    setPhoneNumber("");
                    setIsMonthly(false);
                    setEmail("");
                    setPassword("");
                
                }}>
                    Modifier les informations 
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12 ">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                <div className="flex ">
                                    <div className="mb-4 mr-5 w-6/12">
                                        
                                        <Input
                                            value={name }
                                            onChange={(e)=>setName(e.target.value)}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Prénom"
                                        />
                                        {!name && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}

                                    </div>
                                    <div className="mb-4 w-6/12">
                                        
                                        <Input
                                            value={lastname}
                                            onChange={(e)=>setLastName(e.target.value)}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Nom"
                                        />
                                        {!lastname && <p className="text-snack-pink-original font-normal text-sm">Prénom obligatoire</p>}

                                    </div>
                                </div>
                                <div className="mb-4 ">
                                        
                                        <Input
                                            type="email"
                                            value={email}
                                            onChange={(e)=>setEmail(e.target.value)}
                                            
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="E-mail"
                                        />
                                        {(email.length<1 || !email.match("^[A-Za-z]+[0-9._%+-]*[A-Za-z]*@[A-Za-z]+\.[A-Za-z]+$")) && <p className="text-snack-pink-original font-normal text-sm">E-mail valide obligatoire</p>}

                                </div>
                                <div className="mb-4 ">
                                        
                                        <Input
                                            value={phoneNumber}
                                            onChange={(e)=>{
                                                if((e.target.value.match("^[0-9]*$") != null)&&(e.target.value.length<=10)){
                                                    setPhoneNumber(e.target.value)
                                                }
                                            }}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Numéro de téléphone"
                                        />
                                        {(phoneNumber.length<10) && <p className="text-snack-pink-original font-normal text-sm">Numéro de téléphone de 10 chiffres est obligatoire</p>}
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={()=>{
                            setShowUpdateModal(false);
                            setName("");
                            setLastName("");
                            setPhoneNumber("");
                            setIsMonthly(false);
                            setEmail("");
                            setPassword("");
                        }}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    { name && lastname && email.match("^[A-Za-z]+[0-9._%+-]*[A-Za-z]*@[A-Za-z]+\.[A-Za-z]+$") && phoneNumber.length ==10 ?                           
                    <Button
                        color="green"
                        onClick={UpdateEntreprise}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>: null }
                </ModalFooter>
            </Modal >
        </>
    )
}
export default Restaurants