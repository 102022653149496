import { useMemo } from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { Button, Icon, InputIcon } from '@material-tailwind/react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import Loading from './Loading';

const CardTable = ({ noAdd, onClick, COLUMNS, DATA, title, loading, description }) => {
    const columns = useMemo(() => COLUMNS, [DATA])
    const data = useMemo(() => DATA, [DATA])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        headers,
        rows,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        pageOptions,
        state,
        setGlobalFilter,
        
    } = useTable(
        {
            columns,
            data,
            
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { globalFilter, pageIndex, pageSize } = state

    return (
        <Card>

            <CardBody>
                <div className={'flex justify-between  mb-5'}>
                    <div>
                        <h6 className="uppercase text-gray-400 text-xs font-medium">
                            Overview
                        </h6>
                        <h2 className="text-gray-700 font-bebas text-4xl mb-2">{title} </h2>
                        <p className="text-gray-700 font-SofiaProReg text-sm">{description} </p>
                    </div>
                    { 
                     ((title ==="Liste des utilisateurs")||(title ==="Gestion des commandes")||(title==="Prix des variants")||(title==="Commandes restaurants")||(title==="Factures")) ? null : 
                    <div className={'flex '} >

                        <Button
                            color="lightBlue"
                            className={"ml-5"}
                            buttonType="flled"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                            onClick={(e) => onClick(true)}
                        >
                            Ajouter
                        </Button>

                    </div>
                    }
                </div>
                <div className={'flex justify-end'} >
                    <div className={'w-72 my-2'}>
                        <InputIcon
                            type="text"
                            color="gray"
                            size="regular"
                            outline={true}
                            placeholder="Recherche"
                            iconFamily="material-icons"
                            iconName="search"
                            value={globalFilter}
                            onChange={e => {
                                setGlobalFilter(e.target.value);

                            }}
                        />
                    </div>
                </div>
                <div className="overflow-x-auto">

                    <table {...getTableProps()} className="items-center border-t-2 border-gray-200 w-full bg-transparent border-collapse">
                        <thead>


                            {headerGroups.map((headerGroup, index) => (
                                <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map((column, index) => (
                                        <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())} className="px-2 text-snack-gray-dark align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-medium text-left">
                                            {column.render('Header')}

                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <Icon name="arrow_drop_down" size="xl" />
                                                    : <Icon name="arrow_drop_up" size="xl" />
                                                : ''}

                                        </th>
                                    ))}
                                </tr>
                            ))}


                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {loading ?
                                <Loading rows={headers.length} />
                                :
                                data.length > 0 ?

                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <tr key={index} {...row.getRowProps()} >
                                            {row.cells.map((cell, index) => {
                                                return <th key={index} {...cell.getCellProps()} className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left" >{cell.render('Cell')}</th>
                                            })}
                                        </tr>
                                    )
                                })
                                :
                                    <tr>
                                        <th colSpan={COLUMNS.length} className="text-center font-medium text-snack-gray-dark border-b border-gray-200 align-middle  text-sm whitespace-nowrap px-2 py-4 ">Aucun élement à afficher pour le moment</th>
                                    </tr>
                            }

                        </tbody>
                    </table>

                    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                        <div className="flex-1 flex justify-between sm:hidden">
                            <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Précédent
                            </a>
                            <a
                                href="#"
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Suivant
                            </a>
                        </div>
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Montrant de <span className="font-medium">
                                        {(pageSize * (pageIndex + 1)) - pageSize + 1}</span> jusqu'à <span className="font-medium">{pageSize * (pageIndex + 1)}</span> de{' '}
                                    <span className="font-medium">{rows.length}</span> resultats
                                </p>
                            </div>

                            <div>
                                {pageCount > 5 ?
                                    <nav className="relative z-0 inline-flex rounded-md shadow-sm " aria-label="Pagination">
                                        <button
                                            onClick={() => previousPage()}
                                            disabled={!canPreviousPage}
                                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Previous</span>
                                            <Icon name="navigate_before" size="2xl" />
                                        </button>

                                        <button
                                            onClick={() => gotoPage(0)}
                                            d
                                            className={`z-10 ${pageIndex === 0 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                        >
                                            1
                                        </button>
                                        {
                                            pageIndex <= 2 ?
                                                <div className={"inline-flex"}>
                                                    <button
                                                        onClick={() => gotoPage(1)}

                                                        className={`${pageIndex === 1 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                        2
                                                    </button>
                                                    <button
                                                        onClick={() => gotoPage(2)}
                                                        className={`${pageIndex === 2 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                        3
                                                    </button>
                                                    <button
                                                        onClick={() => nextPage()}
                                                        disabled={!canNextPage}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                        ...
                                                    </button></div>
                                                :
                                                pageIndex >= pageCount - 3 ?
                                                    <div className={"inline-flex"}><button
                                                        onClick={() => previousPage()}
                                                        disabled={!canPreviousPage}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                        ...
                                                    </button><button
                                                        onClick={() => gotoPage(pageCount - 3)}
                                                        className={`${pageIndex === pageCount - 3 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                            {pageCount - 2}
                                                        </button><button
                                                            onClick={() => gotoPage(pageCount - 2)}
                                                            className={`${pageIndex === pageCount - 2 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                        >
                                                            {pageCount - 1}
                                                        </button></div>
                                                    :
                                                    <div className={"inline-flex"}><button
                                                        onClick={() => previousPage()}
                                                        disabled={!canPreviousPage}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                        ...
                                                    </button><button
                                                        onClick={() => gotoPage(pageIndex - 1)}
                                                        className={`bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                    >
                                                            {pageIndex}
                                                        </button><button

                                                            className={`bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                        >
                                                            {pageIndex + 1}
                                                        </button><button
                                                            onClick={() => gotoPage(pageIndex + 1)}
                                                            className={`bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                        >
                                                            {pageIndex + 2}
                                                        </button><button
                                                            onClick={() => nextPage()}
                                                            disabled={!canNextPage}
                                                            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700`}>
                                                            ...
                                                        </button></div>
                                        }

                                        <button
                                            onClick={() => gotoPage(pageCount - 1)}
                                            className={`${pageIndex === pageCount - 1 ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                        >
                                            {pageCount}
                                        </button>
                                        <button
                                            onClick={() => nextPage()}
                                            disabled={!canNextPage}
                                            className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Suivant</span>
                                            <Icon name="navigate_next" size="2xl" />
                                        </button>
                                        <select
                                            value={pageSize}
                                            onChange={e => setPageSize(Number(e.target.value))}>
                                            {[10, 25, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Lister {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </nav>
                                    : <nav className="relative z-0 inline-flex rounded-md shadow-sm " aria-label="Pagination">
                                        <button

                                            onClick={() => previousPage()}
                                            disabled={!canPreviousPage}
                                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Précédent</span>
                                            <Icon name="navigate_before" size="2xl" />
                                        </button>
                                        <div className={"inline-flex"}>
                                            {pageOptions.map((row, index) => (
                                                <button key={index}
                                                    onClick={() => gotoPage(index)}
                                                    className={`${pageIndex === index ? 'bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                                                >
                                                    {index + 1}
                                                </button>
                                            ))}
                                        </div>


                                        <button

                                            onClick={() => nextPage()}
                                            disabled={!canNextPage}
                                            className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Suivant</span>
                                            <Icon name="navigate_next" size="2xl" />
                                        </button>
                                        <select
                                            value={pageSize}
                                            onChange={e => setPageSize(Number(e.target.value))}>
                                            {[10, 25, 50].map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    Lister {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </nav>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card >
    );
}
export default CardTable