import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Icon, Image, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import Select, { components } from 'react-select';
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'
import { UriParser, getIndex } from '../constants/Functions'
import Switch from "react-switch";
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const { ValueContainer, Placeholder } = components;
const Variants = () => {
    // table state
    const [variants, setVariants] = useState([]);
    // select states
    const [dessertsList, setDessertsList] = useState([]);
    const [ingredientsList, setIngredientsList] = useState([]);
    const [occasionsList, setOccasionsList] = useState([]);
    const [ateliersList, setAteliersList] = useState([]);
    const MySwal = withReactContent(Swal)

    // modals showen or hidden true/false
    const [showModal, setShowModal] = useState(false);
    const [showModalt, setShowModalt] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);

    // modal values states
    const [file, setFile] = useState(null);
    const [label, setLabel] = useState('')
    const [summary, setSummary] = useState("")
    const [prix, setPrix] = useState(6)
    const [quantity, setQuantity] = useState(-1)
    const [status, setStatus] = useState(true)
    const [dessert, setDessert] = useState(null)
    const [ing, setIng] = useState([])
    const [occ, setOcc] = useState(null)
    const [atelier, setAtelier] = useState(null)

    // update modal
    const [summaryUp, setSummaryUp] = useState("")
    const [variant_id_update, setVariantUp] = useState('')
    const [labelUp, setLabelUp] = useState('')
    const [prixUp, setPrixUp] = useState(6)
    const [quantityUp, setQuantityUp] = useState(-1)
    const [isCustomizable, setIsCustomizable] = useState(0)
    const [statusUp, setStatusUp] = useState(true)
    const [dessertUp, setDessertUp] = useState(null)
    const [ingUp, setIngUp] = useState([])
    const [occUp, setOccUp] = useState([])
    const [atelierUp, setAtelierUp] = useState(null)

    const animatedComponents = makeAnimated();

    // loading state
    const [loading, setLoading] = useState(true)
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFile(acceptedFiles[0]);
    }, [])
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const COLUMNS = [
        {
            Header: 'Identifiant',
            accessor: 'id',
        },
        {
            Header: 'Image',
            accessor: 'cover',
            Cell: ({ row }) => (
                <div style={{display:"flex"}}>
                    {row.original.cover.filename &&
                    <div> 
                        <Image
                    style={{
                        height:'auto',
                        width:50,
                        
                    }}//http://127.0.0.1:8000
                    src={"https://api-dashboard.snack-time.fr/images/uploaded/"+row.original.cover.filename}
                    alt="dessert snacktime"/>
                     <button className='text-green-400 hover:text-gray-300 mr-2 ml-10' onClick={() => {setVariantUp(row.original.id);setShowImageModal(true);}}>
                    <Icon name="flip_camera_ios" size="2xl" />
                    </button>
                    </div>}
                    
                </div>),
        },
        {
            Header: 'Nom',
            accessor: 'title',
        },
        {
            Header: 'Status',
            accessor: 'isActive',
            Cell: ({ row }) => (
                <div>
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${row.original.isActive ? 'bg-green-100 text-green-800' : 'bg-snack-pink-original text-pink-900'}  `}>
                        {row.original.isActive ? "Active" : "inactive"}
                    </span>
                    <p className={`$`}></p>

                </div>),
        },
        /* {
            Header: 'Description',
            accessor: 'summary',
            Cell: ({ row }) => (
                <div className="w-60">
                    <p className="text-gray">{row.original.summary.slice(0, count) + (row.original.summary.length > count ? "..." : "")}</p>
                </div>),
        }, */
        {
            Header: 'Prix',
            accessor: 'price',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.price.toFixed(2)}€</p>
                </div>),
        },
        {
            Header: 'Quantité disponible',
            accessor: 'quantity',
        },

        /* {
            Header: 'Date Création',
            accessor: 'createdAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.createdAt).toLocaleDateString()}</p>

                </div>),
        },
        {
            Header: 'Date Modification',
            accessor: 'updatedAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.updatedAt).toLocaleDateString()}</p>

                </div>),
        }, */
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title='Modifier' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        setLabelUp(row.original.title)
                        setStatusUp(row.original.isActive)
                        setSummaryUp(row.original.summary)
                        //setIsCustomizable(row.original.isCustomizable)
                        if(row.original.dessert){
                            setDessertUp({ value: row.original.dessert.id, label: row.original.dessert.label })
                        }
                            if(row.original.store){
                            setAtelierUp({ value: row.original.store.id, label:  row.original.store.label })
                        }
                            if(row.original.occasion[0])
                            {
                                setOccUp({ value: row.original.occasion[0].id, label: row.original.occasion[0].label })
                        }
                        setVariantUp(row.original.id)
                        
                        row.original.ingredient.map((item)=> {
                            ingUp.push({ value: item.id, label:  item.label })
                        })  
                        setShowModalt(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    <button title='Supprimer' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => openAlert(row.original.id,row.id)}
                    ><Icon name="delete" size="2xl" /></button>
                </div>),
        }
    ]
    
    var count = 35; //to cut the long summary on table

    useEffect(() => {
        axios.get('/variants')
            .then(res => {
                setVariants(res.data.reverse());
                setLoading(false)
            })
            .catch(err => { 
                console.log(err.response);
                Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })   })
        const dessertlist = [];
        const Ingredientlist = [];
        const Occasionlist = [];
        const Atelierlist = [];
        axios.get('/desserts')
            .then(res => {
                res.data.map(dessert => {
                    dessertlist.push({
                        value: parseInt(dessert.id),
                        label: dessert.label
                    });
                })
                setDessertsList(dessertlist);
            })
            .catch(err => { 
                console.log(err.response);
                Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })   })
            axios.get('/ingredients')
            .then(res => {
                res.data.map(Ingredient => {
                    Ingredientlist.push({
                        value: parseInt(Ingredient.id),
                        label: Ingredient.label
                    });
                })
                setIngredientsList(Ingredientlist);
            })
            .catch(err => {
                console.log(err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                  })  
             })
            axios.get('/occasions')
            .then(res => {
                res.data.map(Occasion => {
                    Occasionlist.push({
                        value: parseInt(Occasion.id),
                        label: Occasion.label
                    });
                })
                setOccasionsList(Occasionlist);
            })
            .catch(err => { 
                console.log(err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                  })  
             })
            axios.get('/stores')
            .then(res => {
                res.data.map(Store => {
                    Atelierlist.push({
                        value: parseInt(Store.id),
                        label: Store.label
                    });
                })
                setAteliersList(Atelierlist);
            })
            .catch(err => { 
                console.log(err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                  })  
             })
    }, [])

    const updateImVar = () => {
        const formData = new FormData();
  
        // Update the formData object
        formData.append(
          "imageFile",
          file,
        );
        let obj;
        axios.post("photos", formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then(res=>{
            obj= {
            "createdAt":new Date(),
            "updatedAt":new Date(),
            "cover":res.data["@id"],
            }
            
            axios.patch(`variants/${variant_id_update}`, obj, {headers:{"content-type": "application/merge-patch+json"}})
            .then(res=>{
                setShowImageModal(false);
                setFile(null);
                axios.get('/variants')
        .then(res=>{
            setVariants(res.data);
        })
        .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
        });
                Swal.fire({
                    toast:true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Image modifiée avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                  })
            })
            .catch(err=>{
                console.log('update dessert im :::', err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Une erreur d'ajout de photo est survenue !",
                  }) 
            })
        
        })
        .catch(err=>{
            console.log('update occ im :::', err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Une erreur d'ajout de photo est survenue !",
              }) 
        })
    }
    

    const postVariant = () => {
        const formData = new FormData();
  
        // Update the formData object
        formData.append(
        "imageFile",
        file,
        );
        formData.append(
            "title",
            label,
          );
        formData.append(
            "summary",
            summary,
          );
        formData.append(
            "price",
            parseFloat(prix),
          );
        formData.append(
            "quantity",
            quantity ,
          );
        formData.append(
            "isCustomizable",
            isCustomizable ,
          );

        formData.append(
            "dessert",
            dessert.value,
          );
        if (occ){
         formData.append(
            "occasion",
            occ.value ,
          ); }
        formData.append(
            "store",
            atelier.value,
          );
        let ingstoadd = "";
        if(ing.length>1)
        {
            ing.map((item)=>{ingstoadd=ingstoadd+','+item.value});
            formData.append(
                "ingredients",
                ingstoadd.substring( ingstoadd.length,1),
              );
        }
        else
        {
            if(ing.length===1){   
            ingstoadd=ing[0].value;
            formData.append(
                "ingredients",
                ingstoadd,
              );
        }
    
    }
        
        axios.post("add/variant/photo", formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then(res=>{
            setDessert(null);
            setOcc(null);
            setAtelier(null);
            setIng([]);
            setLabel("");
            setSummary("")
            setPrix(0)
            setFile(null); 
            setIsCustomizable(false);

            Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Dessert ajoutée avec succès!',
                showConfirmButton: false,
                timer: 1500
            })
            setShowModal(false);
            axios.get('/variants')
            .then(res => {
                setVariants(res.data.reverse());
                setLoading(false)
            })
            .catch(err => { 
                console.log(err.response);
                Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })   })
            
        })
      .catch(error=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Une erreur d'ajout de dessert est survenue !",
          })  
      })
    }

    const openAlert = (delete_id,row_id) => {
        MySwal.fire({
            title: <p>Hello World</p>,
            
            didOpen: () => {
              // `MySwal` is a subclass of `Swal`
              //   with all the same instance & static methods
              MySwal.clickConfirm()
            }
          }).then(() => {
            return Swal.fire({
                title: 'Etes-vous sur de vouloir effacer ce dessert?',
                text: "Cette action n'est pas reversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Supprimer',
                cancelButtonText: 'Annuler'
              }).then((result) => {
                if (result.isConfirmed) {
                    deleteVariant(delete_id,row_id)
                  Swal.fire(
                    'Succès de suppression!',
                    'Votre dessert a été supprimé.',
                    'success'
                  )
                }
              })
          })
      }
    
    
      const deleteVariant = (delete_id,row_id) => {
        axios.delete(`variants/${delete_id}`)
        .then(res=>{
              setVariants(variants.filter((item)=>item.id!==delete_id));
    
        })
        .catch(error=>{
            Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur de suppression est survenue !',
          })  })
      }

      
    const updateVariant = () => {
        let ingred = [] ;
        ingUp.map((item,index)=>{
            ingred.push("/api/ingredients/"+item.value) 
        })
        let body = {
            "title": labelUp,
            "isActive": statusUp,
            "price": parseFloat(prixUp),
            "summary":summaryUp,
            "quantity": quantityUp,
            "isCustomizable": isCustomizable,
            "dessert": "/api/desserts/"+dessertUp.value,
            "store": "/api/stores/"+atelierUp.value,
            "ingredient": ingred
        }
        axios.patch(`variants/${variant_id_update}`, body, {headers:{"content-type": "application/merge-patch+json"}})
        .then(res=>{
            setIngUp([])
            setAtelierUp(null)
            setDessertUp(null)
            setOccUp(null)
            setPrixUp(0);
            setSummaryUp("");
            setQuantityUp(-1)

            Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Dessert modifié avec succès!',
                showConfirmButton: false,
                timer: 1500
            })
            setShowModalt(false)
            
            axios.get('/variants')
            .then(res => {
                setVariants(res.data);
                setLoading(false)
            })
            .catch(err => { 
                console.log(err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                  })  
             })
        })
        .catch(err=>{
            
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de mise à jours est survenue !',
              })  
        }) 
    }
  
    return (
        <>

            <div className="md:pl-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard onClick={setShowModal} COLUMNS={COLUMNS} DATA={variants}
                        description={"Cliquez sur le nom d'une colonne pour trier les données."} title="Gestion des desserts" loading={loading} />
                    </div>
                </div>
                <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
                    <ModalHeader toggler={() => setShowModal(false)}>
                        Ajouter dessert
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                    <div className="flex ">
                                        <div className="mb-4 w-full">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={label}
                                                onChange={(e)=>setLabel(e.target.value)}
                                                outline={true}
                                                placeholder="Nom"
                                            />
                                        {!label && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}

                                        </div>
                                        {/* <div className="flex justify-between content-center mb-4 w-5/12">
                                            <label className="leading-loose block text-m font-medium text-gray-700">Personnalisable</label>
                                            <label className="leading-loose">
                                            <Switch
                                                checked={isCustomizable}
                                                onChange={(checked) => setIsCustomizable(checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={15}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={25}
                                                className="react-switch"
                                                id="material-switch"
                                            /></label>
                                        </div>  */}
                                        </div>
                                                                               
                                        <div className="flex justify-between">

                                        <div className="mb-4 w-5/12">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={prix}
                                                onChange={(e)=>setPrix(e.target.value)}
                                                outline={true}
                                                placeholder="Prix"
                                            />
                                            {!prix && <p className="text-snack-pink-original font-normal text-sm">Prix obligatoire</p>}
                                        </div>
                                        
                                        <div className="mb-4 w-5/12">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={quantity}
                                                onChange={(e)=>setQuantity(e.target.value)}
                                                outline={true}
                                                placeholder="Quantité"
                                            />
                                        {!quantity && <p className="text-snack-pink-original font-normal text-sm">Quantité disponible obligatoire, -1 si illimité</p>}
                                        </div>
                                        </div>
                                        
                                        <div className="flex justify-between">

                                        <div className="mb-4 w-5/12">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                onChange={selectedOption =>
                                                    setDessert(selectedOption)}
                                                value={dessert}
                                                options={dessertsList}
                                                styles={customStyles}
                                                theme={theme}
                                                placeholder="Famille"
                                                isClearable={true} />
                                    {!dessert && <p className="text-snack-pink-original font-normal text-sm">Type de dessert obligatoire</p>}
                                        </div>
                                        <div className="mb-4 w-5/12">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            
                                            onChange={selectedOption =>
                                                setOcc(selectedOption)}
                                            value={occ}
                                            styles={customStyles}
                                            theme={theme}
                                            options={occasionsList}
                                            placeholder="Occasion"
                                            isClearable={true} 
                                            />
                                        {!occ && <p className="text-snack-pink-original font-normal text-sm">Occasion obligatoire</p>}
                                        </div>
                                        </div>

                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={summary}
                                                onChange={(e)=>setSummary(e.target.value)}
                                                outline={true}
                                                placeholder="Description"
                                            />
                                        </div>

                                        <div className="mb-4">
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            onChange={selectedOption =>
                                                setIng(selectedOption)}
                                            value={ing}
                                            isMulti
                                            theme={theme}
                                            options={ingredientsList}
                                            placeholder="Ingrédients"
                                            isClearable={true} 
                                            />
                                        {!ing.length && <p className="text-snack-pink-original font-normal text-sm">Ingrédients obligatoire</p>}
                                        </div>
                                        
                                        <div className="mb-4">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            
                                            onChange={selectedOption =>
                                                setAtelier(selectedOption)}
                                            value={atelier}
                                            styles={customStyles}
                                            theme={theme}
                                            options={ateliersList}
                                            placeholder="Atelier"
                                            isClearable={true} 
                                            />
                                        {!atelier && <p className="text-snack-pink-original font-normal text-sm">Atelier obligatoire</p>}
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Cover photo</label>
                                            <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700' : 'border-gray-300'}  border-dashed rounded-md`}>
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            {
                                                                isDragActive && files !== '' ?
                                                                    null :
                                                                    <span>Importer</span>
                                                            }

                                                            <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        {
                                                            isDragActive ?
                                                                <p className="pl-1">Importez ...</p> :
                                                                <p className="pl-1">ou glissez un fichier ici</p>
                                                        }

                                                    </div>
                                                    {files !== '' ? <ul>{files}</ul> : <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>}


                                                </div>
                                            </div>
                                            {!file  && <p className="text-snack-pink-original font-normal text-sm">Image obligatoire</p>}
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e) => setShowModal(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>
                        {(ing.length && occ && dessert && atelier && prix && quantity && label && file) ? 
                        <Button
                            color="green"
                            onClick={postVariant}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> :
                        <Button
                        disabled
                        color="gray"
                        onClick={postVariant}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>
                         }
                    </ModalFooter>
                </Modal >


                <Modal size="lg" active={showImageModal} toggler={() => setShowImageModal(false)}>
                    <ModalHeader toggler={() => setShowImageModal(false)}>
                        Modifier l'image
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <form action="#" method="POST">
                                        
                                        
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Photo</label>
                                            <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragActive ? 'border-gray-700' : 'border-gray-300'}  border-dashed rounded-md`}>
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            {
                                                                isDragActive && files !== '' ?
                                                                    null :
                                                                    <span>Importer</span>
                                                            }

                                                            <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        {
                                                            isDragActive ?
                                                                <p className="pl-1">Importez ...</p> :
                                                                <p className="pl-1">ou glissez un fichier ici</p>
                                                        }

                                                    </div>
                                                    {files !== '' ? <ul>{files}</ul> : <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>}


                                                </div>
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>


                    </ModalBody >
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e)=>setShowImageModal(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>

                        { file ? <Button
                            
                            color=  "green"
                            onClick={updateImVar}
                            ripple="light"
                        >
                            Enregistrer
                        </Button> : <Button
                            disabled
                            color=  "gray"
                            onClick={updateImVar}
                            ripple="light"
                        >
                            Enregistrer
                        </Button>}
                    </ModalFooter>
                </Modal >
            </div >
            
            <Modal size="lg" active={showModalt} toggler={() => setShowModalt(false)}>
                <ModalHeader toggler={() => setShowModalt(false)}>
                    Modifier un dessert
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={labelUp}
                                                onChange={(e)=>setLabelUp(e.target.value)}
                                                outline={true}
                                                placeholder="Nom"
                                            />
                                            {!labelUp && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}
                                        </div>
                                        <div className="flex justify-between mb-4">
                                        
                                        <div className="mr-20 w-5/12">
                                            <Input
                                                value={quantityUp}
                                                type="Number"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                onChange={e => {
                                                    setQuantityUp(e.target.value);
                                                }}
                                                placeholder="Quantité"
                                            />
                                        {!quantityUp && <p className="text-snack-pink-original font-normal text-sm">Quantité obligatoire</p>}
                                        </div>

                                        <div className='flex justify-between content-center w-5/12'>
                                            <label className="leading-loose block text-m font-medium text-gray-700">status</label>
                                            <label className="leading-loose">
                                                <Switch
                                                checked={statusUp? statusUp : false}
                                                onChange={(checked) => setStatusUp(checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={15}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={25}
                                                className="react-switch"
                                                id="material-switch"
                                            /></label>
                                        </div>

                                        </div>
                                        <div className="flex justify-between">
                                        
                                        

                                        <div className="mb-4 w-full">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            
                                            onChange={selectedOption =>
                                                setOccUp(selectedOption)}
                                            value={occUp}
                                            styles={customStyles}
                                            theme={theme}
                                            options={occasionsList}
                                            placeholder="Occasion"
                                            isClearable={true} 
                                            />
                                        {!occUp && <p className="text-snack-pink-original font-normal text-sm">Occasion obligatoire</p>}
                                        </div>
                                        {/* <div className="flex justify-between content-center mb-4 w-5/12">
                                            <label className="leading-loose block text-m font-medium text-gray-700">Personnalisable</label>
                                            <label className="leading-loose">
                                            <Switch
                                                checked={isCustomizable ? isCustomizable : false}
                                                onChange={(checked) => setIsCustomizable(checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={15}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={25}
                                                className="react-switch"
                                                id="material-switch"
                                            /></label>
                                        </div> */}
                                        </div> 
                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={prixUp}
                                                onChange={(e)=>setPrixUp(e.target.value)}
                                                outline={true}
                                                placeholder="Prix"
                                            />
                                        {!prixUp && <p className="text-snack-pink-original font-normal text-sm">Prix obligatoire</p>}
                                        </div>
                                        

                                        <div className="mb-4">
                                            
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                value={summaryUp}
                                                onChange={(e)=>setSummaryUp(e.target.value)}
                                                outline={true}
                                                placeholder="Description"
                                            />
                                        </div>
                                        <div className="mb-4 " >
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                onChange={selectedOption =>
                                                    setDessertUp(selectedOption)}
                                                value={dessertUp}
                                                options={dessertsList}
                                                styles={customStyles}
                                                theme={theme}
                                                placeholder="Famille"
                                                isClearable={true} />
                                            {!dessertUp && <p className="text-snack-pink-original font-normal text-sm">Dessert obligatoire</p>}
                                        </div>
                                        <div className="mb-4">
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            onChange={selectedOption =>
                                                setIngUp(selectedOption)}
                                            defaultValue={ingUp}
                                            autoFocus
                                            isMulti
                                            theme={theme}
                                            options={ingredientsList}
                                            placeholder="Ingrédients"
                                            isClearable={true} 
                                            />
                                        {!ingUp.length && <p className="text-snack-pink-original font-normal text-sm">Ingrédients obligatoire</p>}
                                        </div>
                                        
                                        <div className="mb-4">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            
                                            onChange={selectedOption =>
                                                setAtelierUp(selectedOption)}
                                            value={atelierUp}
                                            styles={customStyles}
                                            theme={theme}
                                            options={ateliersList}
                                            placeholder="Atelier"
                                            isClearable={true} 
                                            />
                                        {!atelierUp && <p className="text-snack-pink-original font-normal text-sm">Atelier obligatoire</p>}
                                        </div>
                                    

                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModalt(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    {(ingUp.length && occUp && dessertUp && atelierUp && prixUp && quantityUp && labelUp) ?
                    <Button
                        color="green"
                        onClick={updateVariant}
                        ripple="light"
                    >
                        Enregistrer
                    </Button> 
                    : 
                    <Button
                        disabled
                        color="gray"
                        onClick={updateVariant}
                        ripple="light"
                    >
                        Enregistrer
                    </Button> 
                    }
                </ModalFooter>
            </Modal >

        </>
    );
}
export default Variants