import React from 'react'
import { components } from 'react-select';
const { ValueContainer, Placeholder } = components;

export const customStyles = {
    control: base => ({
        ...base,
        height: 44,
        minHeight: 44,
    }),
    placeholder: (base, props) => ({
        ...base,
        marginTop: props.hasValue || props.isFocused ? '-45px' : "0px",
        position: "absolute",
        backgroundColor: props.hasValue || props.isFocused ? "#fff" : "transparent",
        paddingLeft: "5px",
        paddingRight: "5px",
        color:  props.isFocused ? "#04A9F4" : "#BDBDBD",
        transition: "all ease-in-out 300ms",
        fontSize: props.hasValue || props.isFocused ? "0.75rem" : "1rem",
    }),
    valueContainer: base => ({
        ...base,
        overflow: "visibale"
    })
};
export const theme = (theme) => ({
    ...theme,
    borderRadius: 8,
    colors: {
        ...theme.colors,
        neutral20: "#e0e0e0",
        neutral50: "#BDBDBD",
        neutral30: "#e0e0e0",
        primary: "#04A9F4",

    }
})
export const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} hasValue={props.hasValue} isFocused={props.selectProps.menuIsOpen}>

                {props.selectProps.placeholder}
            </Placeholder>
            {
                React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )
            }
        </ValueContainer >
    );
};