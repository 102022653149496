import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

import logo from '../../../assets/img/logo.png';

import axios from 'axios';
import InvoiceTitle from './InvoiceTitle';
import InvoiceNo from './InvoiceNo';
import BillTo from './BillTo';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceThankYouMsg from './InvoiceThankYouMsg';


// Create styles
const styles = StyleSheet.create({
    page: {
        /* fontFamily: 'font-SofiaPro', */
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5, 
        flexDirection: 'column',
        backgroundColor:"#ffffff"
    }, 
    logo: {
        width: 100,
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Create Document Component
const PagePDF = ({invoice, orderDetails,userDetails}) => {

  return(
    <Document>
    <Page size="A4" style={styles.page}>
    {/* <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View> */}
                    <Image style={styles.logo} src={logo} />
                    <InvoiceTitle title={'Facture'}/>
                    <InvoiceNo invoice={invoice }/>
                    <BillTo invoice={userDetails}/>
                    <InvoiceItemsTable invoice={invoice} orders={orderDetails} />
                    <InvoiceThankYouMsg />  
                </Page>
  </Document>
);}
export default PagePDF;