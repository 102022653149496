import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import logo from '../assets/img/snack-dash.png'
import { Image } from '@material-tailwind/react';
import SimpleBarReact from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';

const Sidebar = () => {

    const [showSidebar, setShowSidebar] = useState('-left-64');
    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar}  flex-row flex-nowrap overflow-hidden z-10 md:py-6 md:px-6 transition-all duration-300`}
            >
                <div className="flex-col md:rounded-xl rounded-r-lg bg-white  overflow-hidden items-stretch min-h-full max-h-full  shadow-xl w-64 flex-nowrap pb-4 pl-6 relative">
                    <a
                        href="https://material-tailwind.com?ref=mtd"
                        target="_blank"
                        rel="noreferrer"
                        className="pt-2 sticky top-0 text-center w-full pr-6  bg-white inline-block"
                    >
                        <Image src={logo} rounded={false} />
                        <hr className=" min-w-full" />
                    </a>

                    <SimpleBarReact style={{ maxHeight: "calc(100vh - 150px)"}}>


                        <div className="flex flex-col">
                            <ul className="flex-col  flex list-none">
                                <h1 className={'py-2 mb-1 text-sm text-gray-700 font-bold'}>DASH</h1>
                                <li className="rounded-l-lg">
                                    <NavLink
                                        to="/"
                                        exact
                                        className="flex items-center gap-4 text-sm   text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="assessment" size="xl" />
                                        Statistiques
                                    </NavLink>
                                </li>
                                <h1 className={'py-2 mb-1 text-sm text-gray-700 font-bold'}>DESSERTS</h1>
                                <li className="rounded-l-lg">
                                    <NavLink
                                        to="/occasions"
                                        exact
                                        className="flex items-center gap-4 text-sm   text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="celebration" size="xl" />
                                        Occasions
                                    </NavLink>
                                </li>
                                <li className="rounded-l-lg">
                                    <NavLink
                                        to="/desserts"
                                        exact
                                        className="flex items-center gap-4 text-sm   text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="menu_book" size="xl" />
                                        Familles de desserts
                                    </NavLink>
                                </li>
                                
                                <li className="rounded-lg  ">
                                    <NavLink
                                        to="/ingredients"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="receipt_long" size="xl" />
                                        Ingrédients
                                    </NavLink>
                                </li>
                                <li className="rounded-lg ">
                                    <NavLink
                                        to="/variants"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="cake" size="xl" />
                                        Desserts
                                    </NavLink>
                                </li>
                                
                                <h1 className={'py-2 mb-1 text-sm text-gray-700 font-bold'}>CLIENTS</h1>
                                <li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/users"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="groups" size="xl" />
                                        Liste des clients
                                    </NavLink>
                                </li>
                                <li className="rounded-lg ">
                                    <NavLink
                                        to="/commandes"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="local_shipping" size="xl" />
                                        Commandes clients
                                    </NavLink>
                                </li>
                                <h1 className={'py-2 mb-1 text-sm text-gray-700 font-bold'}>Restaurants</h1>
                                <li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/restaurants"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="store" size="xl" />
                                        Restaurants
                                    </NavLink>
                                </li>
                                <li className="rounded-l-lg">
                                    <NavLink
                                        to="/pots"
                                        exact
                                        className="flex items-center gap-4 text-sm   text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="takeout_dining" size="xl" />
                                        Gestion des pots
                                    </NavLink>
                                </li>
                                <li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/prix-restaurants"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="paid" size="xl" />
                                        Prix pots restaurants
                                    </NavLink>
                                </li>
                                <li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/commandes-restaurants"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="shopping_cart" size="xl" />
                                        Commandes restaurants
                                    </NavLink>
                                </li>
                                <h1 className={'py-2 mb-1 text-sm text-gray-700 font-bold'}>Gains</h1>
                                <li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/factures"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="description" size="xl" />
                                        Toutes les factures
                                    </NavLink>
                                </li>
                                 {/*<li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/maps"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="reviews" size="xl" />
                                        Feedback
                                    </NavLink>
                                </li>
                                <h1 className={'py-2 mb-1 text-sm text-gray-700 font-bold'}>ADMINSTRATION</h1>
                                <li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/maps"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="storefront" size="xl" />
                                        Liste des ateliers
                                    </NavLink>
                                </li>
                                
                                
                                <li className="rounded-lg  text-gray-700">
                                    <NavLink
                                        to="/maps"
                                        className="flex items-center gap-4 text-sm  text-gray-700 font-light  py-3 rounded-l-lg"
                                        activeClassName="bg-gradient-to-l from-snack-blue-light via-snack-blue-light to-transparent border-blue-600 border-r-4 text-gray-700 "
                                    >
                                        <Icon name="badge" size="xl" />
                                        Gestionnaires du dashboard
                                    </NavLink>
                                </li> */}
                                
                            </ul>


                        </div>
                    </SimpleBarReact>
                </div>
            </div>
        </>
    );
}
export default Sidebar