import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import InputIcon from '@material-tailwind/react/InputIcon';
import Button from '@material-tailwind/react/Button';

import Page from './login/Page';
import Container from './login/Container';
import { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { loginUser } from 'redux/features/authSlice';
import { Image } from '@material-tailwind/react';
import SnacktimeLogo from 'assets/img/snack-dash.png';
import SnacktimeBanner from 'assets/img/Groupe1487.png';
import SnacktimeFooter from 'assets/img/Groupe1439.png';
import Swal from 'sweetalert2';
export default function Login() {
    const [username, setUsername] = useState('') 
    const [password, setPassword] = useState('') 
    let history = useHistory();
    const dispatch = useDispatch();

    const connexion = () => {
        let credentials = {
            'username' : username,
            'password': password
        }//https://api-dashboard.snack-time.fr/api
        axios.post("https://api-dashboard.snack-time.fr/api/login", credentials)
        .then(res=>{

            dispatch(loginUser(res.data));
            history.push('/dashboard');
        })
        .catch(err=> {
            console.log("erreur de conex:", err.data);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Username ou bien mot de passe incorrecte !',
              })  ;
              setUsername("");
              setPassword("");
            });
        
    }
    return (
        <Page>
            <div className={'w-screen flex flex-row mb-5'}>
            <Image
                        src={SnacktimeBanner}
                        alt="Strawberries SnackTime"
                        width="50%"
                        
                        />
            <div className='mr-20'></div>
            <Image
                        src={SnacktimeBanner}
                        alt="Strawberries SnackTime"
                        width="50%"
                        
                        />
            </div>
            <Container>
            
                <Card>
                    <CardHeader color="lightBlue">
                        <Image
                        src={SnacktimeLogo}
                        alt="Logo SnackTime"
                        height="auto"
                        width={180}
                        />
                    </CardHeader>

                    <CardBody>
                        <div className="mb-12 px-4 bg-bb">
        
                        <InputIcon
                                type="email"
                                color="lightBlue"
                                placeholder="Email Address"
                                onChange= {(e)=>{setUsername(e.target.value)}}
                                iconName="email"
                            />
                        </div>
                        <div className="mb-8 px-4">
                            <InputIcon
                                type="password"
                                color="lightBlue"
                                placeholder="Password"
                                onChange= {(e)=>{setPassword(e.target.value)}}
                                iconName="lock"
                            />
                        </div>
                        {/* <div className="mb-4 px-4">
                            <Checkbox
                                color="lightBlue"
                                text="Remember Me"
                                id="remember"
                            />
                        </div> */}
                    </CardBody>
                    <CardFooter>
                        <div className="flex justify-center bg-bb">
                            <Button
                                color="lightBlue"
                                buttonType="link"
                                size="lg"
                                ripple="dark"
                                onClick={connexion}
                            >
                                Se connecter
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Container>
            <div className={'w-screen flex flex-row mt-10'}>
            <Image
                        src={SnacktimeFooter}
                        alt="Strawberries SnackTime"
                        width="50%"
                        
                        />
            <Image
                        src={SnacktimeFooter}
                        alt="Strawberries SnackTime"
                        width="50%"
                        
                        />
            </div>
        </Page>
    );
}
