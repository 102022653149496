import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceNoContainer: {
        flexDirection: 'column',
        marginTop: 36,
       
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 100
    }

});


const InvoiceNo = ({ invoice }) => (
    <Fragment>
        <View style={styles.invoiceContainer}>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Facture No:</Text>
            <Text style={styles.label}>Type de paiement:</Text>
            <Text style={styles.label}>Date: </Text>

        </View >
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.invoiceDate}>{invoice.id}</Text>
            <Text style={styles.invoiceDate}>  {invoice.mode === "COD" ? "En espèces" :
                invoice.mode === "Card" ? "Carte Bancaire" :
                    invoice.mode === "TXN" ? "Virement bancaire" :
                        "Virement mensuel"}</Text>
            <Text >{(new Date(invoice.createdAt)).toLocaleDateString()}</Text>
        </View >
        </View>
    </Fragment>
);

export default InvoiceNo