import React, { useEffect, useState } from 'react'
import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import Select from 'react-select';
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'
import Swal from 'sweetalert2'
import OrderDetails from 'components/childComponents/OrderDetails';

const Commandes = () => {

    const [showModalUp, setShowModalUp] = useState(false);
    const [showModalDetails, setShowModalDetails] = useState(false);
    const [name, setName] = useState('')
    const [orders, setOrders] = useState([])
    const [detailsId, setDetailsId] = useState(null)
    const [ordersDetails, setOrdersDetails] = useState([])
    const [orderObject, setOrderObject] = useState({})
    const [id, setId] = useState(0)
    const [loading, setLoading] = useState(true)   
    const [detailsIngredients, SetDetailsIngredients] = useState(null)

    const COLUMNS = [
        {
            Header: 'Identifiant',
            accessor: 'id',
        },
        {
            Header: 'Client',
            accessor: 'user',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.user.email}</p>
                    
                </div>),
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => (
                <div>
                    <p className={` inline-flex text-xs leading-5 font-medium rounded-full px-3 py-1 text-white ${row.original.status === "new" ? 'bg-snack-pink-original ' : (row.original.status === "delivered" || row.original.status === "paid") ? 'bg-snack-green-original ' : 'bg-yellow-500 '}  } `}>
                        {row.original.status === "new" ? "Nouveau" : 
                    row.original.status === "checkout" ? "En cours de finalisation" :
                    row.original.status === "paid" ? "Payée" :
                    row.original.status === "failed" ? "Erreur" :
                    row.original.status === "delivering" ? "En cours de livraison" :
                    "Livrée"}</p>
                    
                </div>),
        },
        {
            Header: 'Mode de paiement',
            accessor: 'mode',
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.total.toFixed(2)}€</p>
                </div>),
        },
        {
            Header: 'Sous Total',
            accessor: 'subTotal',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.subTotal.toFixed(2)}€</p>
                </div>),
        },
        /* {
            Header: 'Réduction',
            accessor: 'discount',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.discount? row.original.discount : 0}</p>
                    
                </div>),
        }, */
        {
            Header: 'Date de livraison',
            accessor: 'shouldDeliveryAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.shouldDeliveryAt).toLocaleString()}</p>
                    
                </div>),
        },
        
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title='Modifier' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        setId(row.original.id)
                        setShowModalUp(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    <button title='Consulter' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        getDetails(row.original.id)
                    }}
                    ><Icon name="visibility" size="2xl" /></button>
                </div>),
        }
    ]

    const getDetails = (id) => {
        axios.get(`/order_items?page=1&order=${id}`)
        .then(res=>{
            setOrdersDetails(res.data);
            let obj = orders.find((item)=>item.id===id)
            setOrderObject(obj)
            console.log("items",res.data); 
        })
        .catch(err=>{
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
            })  
            })
    }
    useEffect(() => {
        if (detailsIngredients != undefined && detailsIngredients.length > 0) {
            setShowModalDetails(true)
        }
    }, [ordersDetails]);

    useEffect(() => {
        let orderFiltered = [];
        axios.get('/orders')
         .then(res=>{
                res.data.map((item)=>{
                    if (item.user.isEntreprise === false) {
                       orderFiltered.push(item)                    
                    }
                })
                setOrders(orderFiltered.reverse());
                setLoading(false)
         })
         .catch(err=>{
            console.log(err.response);
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
            })
            axios.get("/ingredients?exists%5Bprice%5D=true")
            .then(resIngs => {
                SetDetailsIngredients(resIngs.data);
            })
            .catch(err => {
                console.log("err in ings", err);
            })
     }, [])

    let stateOptions = [
        {label:"Payé",value:'paid'},
        {label:"En cours de livraison",value:'delivering'},
        {label:"Livré",value:'delivered'},
    ]
    const UpdateStatus = () => {

        axios.patch(`orders/${id}`, {"status" : name.value}, {headers:{"content-type": "application/merge-patch+json"}})
        .then(res=>{
                setName("");
                setShowModalUp(false)
                axios.get('/orders')
                .then(res=>{
                    setOrders(res.data);
                })
                .catch(err=>{
                    console.log(err.response);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur est survenue !',
                      })  
                })
              Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Etat modifiée avec succès!',
                showConfirmButton: false,
                timer: 1500
              })
    
        })
        .catch(error=>{
            console.log(error.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
        })

    }
    
    return (
        <>

            <div className="md:pl-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard onClick={setShowModalUp} COLUMNS={COLUMNS} 
                        description={"Cliquez sur le nom d'une colonne pour trier les données."} 
                        title="Gestion des commandes" DATA={orders} loading={loading} />
                    </div>
                </div>
                </div >
            <Modal size="lg" active={showModalUp} toggler={() => setShowModalUp(false)}>
                <ModalHeader toggler={() => setShowModalUp(false)}>
                    Modifier état commande
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                    <div className="mb-4">
                                                <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>
                                                        setName(selectedOption)}
                                                    value={name}
                                                    options={stateOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Status de la commande"
                                                    isClearable={true} />
                                    {!name && <p className="text-snack-pink-original font-normal text-sm">Label obligatoire</p>}
                                       
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModalUp(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    {name ? 
                    <Button
                        color="green"
                        onClick={UpdateStatus}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>
                    : 
                    <Button
                        disabled
                        color="gray"
                        onClick={UpdateStatus}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>}
                </ModalFooter>
            </Modal >
            <Modal size="lg" active={showModalDetails} toggler={() => setShowModalDetails(false)}>
                <ModalHeader toggler={() => setShowModalDetails(false)}>
                    Détails commande
                </ModalHeader>
                <ModalBody>
                <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr >
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Produit</th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantité</th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Ingrédients</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">

                                                        {(ordersDetails.length > 0) ? ordersDetails.map((item, index) => (
                                                            <OrderDetails item={item} detailsIngredients={detailsIngredients} key={index} />
                                                        ))
                                                            :
                                                            <tr className="mt-6 flex w-full items-end justify-center gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                                                <td>Aucun élement à afficher</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" flex w-full justify-end">
                                        <p className="text-snack-pink-original font-normal text-lg">Total:</p>
                                        <p className="text-gray font-bold text-lg">{orderObject.total ? orderObject.total.toFixed(2) + " euros" : ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModalDetails(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    
                </ModalFooter>
            </Modal >

        </>
    );
}
export default Commandes