import {  createStore, applyMiddleware, combineReducers } from 'redux';

import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import authReducer from './features/authSlice'

const rootReducer = combineReducers({
    auth: authReducer
  
})

const persistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['auth'] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);
//add logger as second param to the middleware to get log on console each time the state changes
const middleware = applyMiddleware(thunk);

const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

export default store;
export { persistor, store };