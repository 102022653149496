import {  useLocation } from 'react-router-dom';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import NavbarInput from '@material-tailwind/react/NavbarInput';
import Image from '@material-tailwind/react/Image';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';
import ProfilePicture from 'assets/img/user_avatar.png';
import { useDispatch } from 'react-redux';
import { loginUser } from 'redux/features/authSlice';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const AdminNavbar = ({ showSidebar, setShowSidebar }) => {
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    let history = useHistory();

    const logout = () => {//http://127.0.0.1:8000/api/logout
        axios.post("https://api-dashboard.snack-time.fr/api/logout")
        .then(res=>{
            
            dispatch(loginUser({
                id : 0,
                email : "",
                roles : []
               }));
            history.push('/');
        })
        .catch(err=> {
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
                })
    }
    return (
        <nav className=" md:ml-64 py-6 px-4">
            <div className="container bg-white md:bg-opacity-0 rounded-xl max-w-full mx-auto flex items-center justify-between  md:pl-8">
                <div className="md:hidden">
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        iconOnly
                        rounded
                        ripple="light"
                        onClick={() => setShowSidebar('left-0')}
                    >
                        <Icon name="menu" size="2xl" color="gray" />
                    </Button>
                    <div
                        className={`absolute top-2 md:hidden ${showSidebar === 'left-0' ? 'left-64' : '-left-64'
                            } z-50 transition-all duration-300`}
                    >
                        <Button
                            color="transparent"
                            buttonType="link"
                            size="lg"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => setShowSidebar('-left-64')}
                        >
                            <Icon name="close" size="2xl" color="gray" />
                        </Button>
                    </div>
                </div>

                <div className="flex justify-between bg-white rounded-xl p-6 items-center w-full">
                    <h4 className="uppercase text-gray-700 text-sm tracking-wider mt-1">
                        {location === '/'
                            ? 'DASHBOARD'
                            : location.toUpperCase().replace('/', '')}
                    </h4>

                    <div className="flex">
                        <NavbarInput placeholder="Recherche" />
                        <div className="-mr-4 ml-6">
                            <Dropdown
                                color="transparent"
                                buttonText={
                                    <div className="w-12">
                                        <Image src={ProfilePicture} rounded />
                                    </div>
                                }
                                rounded
                                style={{
                                    padding: 0,
                                    color: 'transparent',
                                }}
                            >
                                <DropdownItem color="lightBlue" onClick={logout}>
                                    Se déconnecter
                                </DropdownItem>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
export default AdminNavbar