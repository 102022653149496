import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    dessert: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    title: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    price: {
        width: '20%',
        
    },
    amount: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
});

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.dessert}>Dessert</Text>
        <Text style={styles.title}>Nom du dessert</Text>
        <Text style={styles.amount}>Quantité</Text>
        <Text style={styles.price}>Prix</Text>
    </View>
);

export default InvoiceTableHeader