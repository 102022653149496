import StatusCard from '../childComponents/StatusCard';
import PageVisitsCard from '../childComponents/PageVisitsCard';
import axios from '../../axios/axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

const Dashboard = () => {
    const [ordersCheckout, setOrdersCheckout] = useState([])
    const [ordersNew, setOrdersNew] = useState([])
    const [ordersPaid, setOrdersPaid] = useState([])
    const [activeUsers, setActiveUsers] = useState([])
    const [revenus, setRevenus] = useState(0)
    const [totals, setTotals] = useState([0])

    useEffect(() => {
        axios.get('/orders?status=checkout')
         .then(res=>{
             let checkout = res.data
             setOrdersCheckout(checkout);
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de récupération de commandes est survenue !',
              })  
         })
        axios.get('/orders?status=new')
         .then(res=>{
             let checkout = res.data
             setOrdersNew(checkout);
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de récupération de commandes est survenue !',
              })  
         })
        axios.get('/orders?status=paid')
         .then(res=>{
            setOrdersPaid(res.data);
             let result = 0;

             res.data.forEach(item => {
            result += item.total;
            })
            setRevenus(result);
         })
         .catch(err=>{
             console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de récupération de commandes payé est survenue !',
              })  
         })
        axios.get('/users?isBlocked=0&isEntreprise=0')
         .then(res=>{
             let users =res.data
            setActiveUsers(users);
         })
         .catch(err=>{
            console.log(err.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur de récupération de users est survenue !',
              })  
         })
         
    }, [])
    
    
    return (
        <>
           

            

            <div className="px-3 md:px-8 mt-4">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-4">
                        <StatusCard
                            color="pink"
                            icon="groups"
                            title="Utilisateurs actives"
                            amount={(activeUsers.length).toString()}
                           
                        />
                        
                        <StatusCard
                            color="purple"
                            icon="trending_up"
                            title="Commandes en attente de livraison"
                            amount={(ordersCheckout.length || ordersNew.length) ? (ordersCheckout.length+ordersNew.length).toString() : "0"}
                            //percentage="1.10"
                            //percentageIcon="arrow_downward"
                            //percentageColor="orange"
                            //date="Since yesterday"
                        />
                        <StatusCard
                            color="pink"
                            icon="paid"
                            title="Revenus clients non mensuels"
                            amount={revenus.toString()}
                            
                        />
                       
                    </div>
                    
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                            <PageVisitsCard />
                        
                </div>
            </div>
        </>
    );
}
export default Dashboard