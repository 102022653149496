import React, { useEffect, useState } from 'react'
import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import TableCard from '../childComponents/TableCard';
import axios from '../../axios/axios'
import axios1 from 'axios'
import Select from 'react-select';
import { customStyles, theme, CustomValueContainer } from '../constants/ReactSelect'
import Swal from 'sweetalert2'
import { PDFViewer } from '@react-pdf/renderer';
import PagePDF from 'components/childComponents/invoice/InvoicePDF';
import OrderDetails from 'components/childComponents/OrderDetails';
import { PdfIcon } from 'components/childComponents/svgIcons';

const CommandesRestaurants = () => {

    const [showModalUp, setShowModalUp] = useState(false);
    const [showModalDetails, setShowModalDetails] = useState(false);
    const [name, setName] = useState('')
    const [orders, setOrders] = useState([])
    const [detailsId, setDetailsId] = useState(null)
    const [ordersDetails, setOrdersDetails] = useState([])
    const [orderObject, setOrderObject] = useState({})
    const [id, setId] = useState(0)
    const [loading, setLoading] = useState(true)   
    const [loading2, setLoading2] = useState(true)   
    const [viewPDF, setViewPDF] = useState(false)
    const [viewInv, setViewInvoice] = useState(false)
    const [total, setTotal] = useState(0)
    const [invoice, setInvoice] = useState({})
    const [detailsIngredients, SetDetailsIngredients] = useState(null)
    const [isMonthly, setIsMonthly] = useState(false)
    const [orderBL, setOrderBL] = useState('');
    const [orderInvoice, setOrderInv] = useState('');

    const COLUMNS = [
        {
            Header: 'Id',
            accessor: 'id',
        },
        {
            Header: 'Client',
            accessor: 'user',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.user.email}</p>
                </div>),
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => (
                <div>
                    <span className={` inline-flex text-xs leading-5 font-medium rounded-full px-3 py-1 text-white ${row.original.status === "new" ? 'bg-snack-pink-original ' : (row.original.status === "delivered" || row.original.status === "paid" || row.original.status ==="delivered and paid") ? 'bg-snack-green-original ' : 'bg-yellow-500 '}  } `}>
                    {row.original.status === "new" ? "Nouveau" : 
                    row.original.status === "checkout" ? "En cours de finalisation" :
                    row.original.status === "paid" ? "Payée" :
                    row.original.status === "failed" ? "Erreur" :
                    row.original.status === "delivering" ? "En cours de livraison" :
                    row.original.status === "delivered and paid" ? "Livrée et payée" :
                    "Livrée"}
                    </span>
                    
                    
                </div>),
        },
        {
            Header: 'Mode',
            accessor: 'mode',
            Cell : ({row})=>(
                <div>
                    <p className="text-snack-gray-dark ">{row.original.mode === "COD" ? "En espèces" :
                row.original.mode === "Card" ? "Carte Bancaire" :
                row.original.mode === "TXN" ? "Virement bancaire" :
                        "Virement mensuel"}</p>
                </div>
            )
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.total.toFixed(2)}</p>
                </div>),
        },
        {
            Header: 'Sous Total',
            accessor: 'subTotal',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.subTotal.toFixed(2)}</p>
                </div>),
        },
        {
            Header: 'Date de livraison',
            accessor: 'shouldDeliveryAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.shouldDeliveryAt).toLocaleString()}</p>
                    
                </div>),
        },
        {
            Header: "Bon de Livraison",
            accessor: "[BL]",
            Cell: ({ row }) => (
                <button title='Bon de Livraison' className=' text-gray-400 hover:text-snack-pink-original mr-2' onClick={() => {
                    // http://127.0.0.1:8001
                    if(row.original.BonLivraison){
                        setOrderBL(row.original.BonLivraison)
                        setViewPDF(true)
                    }else {
                    axios.get(`https://api.snack-time.fr/v1/orderBL/${row.original.id}`)
                        .then(res => {
                            console.log("pdf", res.data);
                            setOrderBL(res.data)
                            setViewPDF(true)

                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Une erreur est survenue !',
                            })
                        })
                    }}}
                    > 
                    <PdfIcon/>
                    </button>
            )
        },
        {
            Header: "Facture",
            accessor: "[INV]",
            Cell: ({ row }) => (
                row.original.user.monthlySubscribed ? <p className="text-gray-800 font-medium">---</p> : 
                <button title='Facture' className='text-gray-400 hover:text-snack-pink-original mr-2' onClick={() => {                   
                    // http://127.0.0.1:8001
                    axios.get(`https://api.snack-time.fr/v1/orderInvoice/${row.original.id}`)
                        .then(res => {
                            console.log("fact", res.data);
                            setOrderInv(res.data)
                            setViewInvoice(true)
                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Une erreur est survenue !',
                            })
                        })
                    }}
                    > 
                    <PdfIcon/>
                    </button>
            )
        },
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button title='Modifier' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        setId(row.original.id)
                        setIsMonthly(row.original.user.monthlySubscribed);
                        setShowModalUp(true)
                    }}
                    ><Icon name="mode_edit" size="2xl" /></button>
                    <button title='Consulter' className='text-gray-400 hover:text-gray-300 mr-2' onClick={() => {
                        getDetails(row.original.id)
                    }}
                    ><Icon name="visibility" size="2xl" /></button>
                </div>),
        }
    ]

    const getDetails = (id) => {
        axios.get(`/order_items?order=${id}`)
        .then(res=>{
            setOrdersDetails(res.data);
            let obj = orders.find((item)=>item.id===id)
            setOrderObject(obj)
            setTotal(obj.total)
            console.log("items",res.data); 
        })
        .catch(err=>{
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
            })  
            })
    }

    useEffect(() => {
        let orderFiltered = [];
        axios.get('/orders')
         .then(res=>{
             console.log(res.data);
                res.data.map((item)=>{
                    if (item.user.isEntreprise === true) {
                       orderFiltered.push(item)                    
                    }
                })
                setOrders(orderFiltered.reverse());
                setLoading(false)
         })
         .catch(err=>{
            console.log(err.response);
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
            })
            axios.get("/ingredients?exists%5Bprice%5D=true")
            .then(resIngs => {
                SetDetailsIngredients(resIngs.data);
            })
            .catch(err => {
                console.log("err in ings", err);
            })
     }, [])

    let stateOptionsMensuel = [
        /* {label:"En cours de livraison",value:'delivering'}, */
        {label:"Livré",value:'delivered'},
    ]
    let stateOptions = [
        {label:"Livré et payé",value:'delivered and paid'},
        /* {label:"En cours de livraison",value:'delivering'}, */
        {label:"Livré",value:'delivered'},
    ]
    const UpdateStatus = () => {

        axios.patch(`updateStatus/${id}`, {"status" : name.value}, {headers:{"content-type": "application/merge-patch+json"}})
        .then(res=>{
                setName("");
                setShowModalUp(false)
                //https://api.snack-time.frhttp://127.0.0.1:8001
                axios1.post("https://api.snack-time.fr/v1/invoices/add", {"order" : res.data})
                .then((res)=>{
                    console.log(res.data);
                axios.get('/orders')
                .then(res=>{
                    setOrders(res.data.reverse());
                })
                .catch(err=>{
                    console.log(err.response);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur est survenue !',
                      })  
                })})
              .catch(err=>console.log(err))
              Swal.fire({
                toast:true,
                position: 'top-end',
                icon: 'success',
                title: 'Etat modifiée avec succès!',
                showConfirmButton: false,
                timer: 1500
              })
    
        })
        .catch(error=>{
            console.log(error.response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenue !',
              })  
        })

    }
    useEffect(() => {
        if (detailsIngredients != undefined && detailsIngredients.length > 0) {
            setShowModalDetails(true)
        }
    }, [ordersDetails]);
    return (
        <>

            <div className="md:pl-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard onClick={setShowModalUp} COLUMNS={COLUMNS}
                        description={"Cliquez sur le nom d'une colonne pour trier les données."} 
                        title="Commandes restaurants" DATA={orders} loading={loading} />
                    </div>
                </div>
                </div >
            <Modal size="lg" active={showModalUp} toggler={() => setShowModalUp(false)}>
                <ModalHeader toggler={() => setShowModalUp(false)}>
                    Modifier état commande
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="md:grid md:grid-cols-12 md:gap-12">
                            <div className="md:col-span-12">
                                <form action="#" method="POST">
                                    <div className="mb-4">
                                                <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    onChange={selectedOption =>
                                                        setName(selectedOption)}
                                                    value={name}
                                                    options={isMonthly ? stateOptionsMensuel : stateOptions}
                                                    styles={customStyles}
                                                    theme={theme}
                                                    placeholder="Etat de la commande"
                                                    isClearable={true} />
                                    {!name && <p className="text-snack-pink-original font-normal text-sm">Etat obligatoire</p>}
                                       
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>


                </ModalBody >
                <ModalFooter>
                    <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModalUp(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    {name ? 
                    <Button
                        color="green"
                        onClick={UpdateStatus}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>
                    : 
                    <Button
                        disabled
                        color="gray"
                        onClick={UpdateStatus}
                        ripple="light"
                    >
                        Enregistrer
                    </Button>}
                </ModalFooter>
            </Modal >
            
            <Modal size="lg" active={showModalDetails} toggler={() => setShowModalDetails(false)}>
                <ModalHeader toggler={() => setShowModalDetails(false)}>
                    Détails commande
                </ModalHeader>
                <ModalBody>
                <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr >
                                                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-2/5">Produit</th>
                                                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-1/5">Quantité</th>
                                                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-2/5"> Ingrédients</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">

                                                        {(ordersDetails.length > 0) ? ordersDetails.map((item, index) => (
                                                            <OrderDetails item={item} detailsIngredients={detailsIngredients} key={index} />
                                                        ))
                                                            :
                                                            <tr className="mt-6 flex w-full items-end justify-center gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                                                <td>Aucun élement à afficher</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" flex w-full justify-end">
                                        <p className="text-gray font-normal font-sofiapro text-lg">Total :</p>
                                        <p className="text-snack-pink-original font-bold text-lg">{total.toFixed(2) + " €"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                </ModalBody>
                <ModalFooter>
                    <Button
                        color="red"
                        onClick={(e) => setShowModalDetails(false)}
                        ripple="dark"
                    >
                        Fermer
                    </Button>
                    
                </ModalFooter>
            </Modal >


            {viewPDF ? (
                    <div className="h-screen max-h-screen">
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none"
                        >
                            <div className="relative w-full my-6 h-4/5 mx-auto overflow-y-auto max-w-3xl z-50">
                                <iframe
                                    height={"100%"}
                                    width="100%"
                                    //https://api.snack-time.frhttp://127.0.0.1:8001
                                    src={`https://api.snack-time.fr/${orderBL}`} />
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => setViewPDF(false)}></div>
                        </div>

                    </div>
                ) : null}


            {viewInv ? (
                    <div className="h-screen max-h-screen">
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none"
                        >
                            <div className="relative w-full my-6 h-4/5 mx-auto overflow-y-auto max-w-3xl z-50">
                                <iframe
                                    height={"100%"}
                                    width="100%"
                                    //https://api.snack-time.frhttp://127.0.0.1:8001
                                    src={`https://api.snack-time.fr/${orderInvoice}`} />
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => setViewInvoice(false)}></div>
                        </div>

                    </div>
                ) : null}


        </>
    );
}
export default CommandesRestaurants